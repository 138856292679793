import { createStyles } from "@mantine/core";
import React from "react";
import { IoIosClose } from "react-icons/io";

import AddButton from "../../../components/Buttons/AddButton";
import TextInput from "../../../components/TextInputs";

const useStyles = createStyles((theme) => ({
	input: {
		width: "100%",
		marginBottom: theme.spacing.sm,
	},
	website: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	closeIcon: {
		height: theme.spacing.xl,
		width: theme.spacing.xl,
		marginRight: theme.spacing.sm,
	},
}));

const Websites = ({ handleChange, websites }) => {
	const { classes } = useStyles();

	const addWebsite = () => handleChange("websites", [...websites, ""]);

	const updateWebsite = (newWebsite, idxToUpdate) =>
		handleChange(
			"websites",
			websites.map((Website, idx) =>
				idxToUpdate === idx ? newWebsite : Website
			)
		);

	const removeWebsite = (idx) =>
		handleChange(
			"websites",
			websites.filter((_, i) => idx !== i)
		);

	return (
		<div className={classes.form}>
			{websites.map((website, idx) => (
				<div className={classes.website} key={`website_${idx}`}>
					<IoIosClose
						className={classes.closeIcon}
						onClick={() => {
							removeWebsite(idx);
						}}
					/>

					<TextInput
						placeholder="ex: tapizi.com"
						label="Site internet"
						value={website}
						onChange={({ currentTarget }) =>
							updateWebsite(currentTarget.value, idx)
						}
						handleClearForm={() => updateWebsite("", idx)}
					/>
				</div>
			))}
			<AddButton label="Nouveau site web" onClick={addWebsite} />
		</div>
	);
};

export default Websites;
