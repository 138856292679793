import { createStyles, Paper } from "@mantine/core";
import { AiFillEdit } from "react-icons/ai";
import paths from "../../../../paths";
import { useNavigate } from "react-router-dom";

import Text from "../../../../components/Text";

const useStyles = createStyles((theme) => ({
	paper: {
		display: "flex",
		flexDirection: "row",
		height: 50,
		width: "100%",
		justifyContent: "space-between",
		alignItems: "center",
		marginTop: theme.spacing.sm,
		marginBottom: theme.spacing.sm,
	},
	icon: {
		height: theme.spacing.md,
		width: theme.spacing.md,
	},
}));

const Accessory = ({ accessory }) => {
	const { classes } = useStyles();
	const navigate = useNavigate();

	return (
		<Paper
			className={classes.paper}
			shadow="xs"
			p="md"
			onClick={() =>
				navigate(paths.accessoryEdition.baseUrl, { state: { accessory } })
			}
		>
			<Text variant="overflowText">{accessory.name}</Text>
			<AiFillEdit className={classes.icon} />
		</Paper>
	);
};

export default Accessory;
