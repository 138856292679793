import { createStyles, Text as MantineText } from "@mantine/core";

const useStyles = createStyles((theme) => ({
	h1: {
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 700,
		fontSize: 26,
		color: theme.colors.white,
	},
	mainTitle: {
		height: 40,
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 700,
		fontSize: 22,
		color: theme.colors.white,
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		maxWidth: "100%",
	},
	title: {
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 700,
		fontSize: 18,
		color:
			theme.colorScheme === "dark" ? theme.colors.white : theme.colors.blue,
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		maxWidth: "100%",
	},
	subtitle: {
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 700,
		fontSize: 18,
		color:
			theme.colorScheme === "dark" ? theme.colors.white : theme.colors.blue,
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		maxWidth: "100%",
	},
	text: {
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 400,
		fontSize: 16,
	},
	overflowText: {
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 400,
		fontSize: 16,
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		maxWidth: "100%",
	},
	light: {
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 100,
		fontSize: 16,
		color:
			theme.colorScheme === "dark" ? theme.colors.white : theme.colors.dark,
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		maxWidth: "100%",
	},
}));

/**
 * If the component becomes too complex with too many props,
 * please split it into several components (title, text, ...)
 *
 * @param {title | light} variant
 */
const Text = ({ variant, color, children }) => {
	const { classes } = useStyles();

	if (variant === "h1")
		return <MantineText className={classes.h1}>{children}</MantineText>;
	else if (variant === "mainTitle")
		return <MantineText className={classes.mainTitle}>{children}</MantineText>;
	else if (variant === "title")
		return <MantineText className={classes.title}>{children}</MantineText>;
	else if (variant === "subtitle")
		return <MantineText className={classes.subtitle}>{children}</MantineText>;
	else if (variant === "light")
		return <MantineText className={classes.light}>{children}</MantineText>;
	else if (variant === "overflowText")
		return (
			<MantineText
				sx={(theme) => ({
					color: color
						? color
						: theme.colorScheme === "dark"
						? theme.colors.white
						: theme.colors.dark,
				})}
				className={classes.overflowText}
			>
				{children}
			</MantineText>
		);
	else
		return (
			<MantineText
				sx={(theme) => ({
					color: color
						? color
						: theme.colorScheme === "dark"
						? theme.colors.white
						: theme.colors.dark,
				})}
				className={classes.text}
			>
				{children}
			</MantineText>
		);
};

export default Text;
