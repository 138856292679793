const MantineTheme = {
	// -*-*-*-*-*-*-*-*-*-*-*-*- Colors -*-*-*-*-*-*-*-*-*-*-*-*-
	// Theme is deeply merged with default theme
	// colorScheme: "light",
	colors: {
		// Global colors
		globalBackground: "linear-gradient(80deg, #1900C7 0%, #FB00FF 100%);",
		lightBackground: ["#fafafa"],
		darkBackground: ["#2C3232"],
		pink: "#FB00FF",
		blue: "#1900C7",
		success: "#00DCA3",
		redAlert: "#FF0000",
		lightRedAlert: "#FF4040",

		// Social media colors
		twitter: "#00acee",
		facebook: "#4267B2",
		instagram: "#C13584",
		linkedin: "#0A66C2",
		tiktok: "#000000",
		skype: "#299acf",
		youtube: "#f10101",
		twitch: "#8942f3",
		snapchat: "#f2ef08",
		pinterest: "#dc0424",

		lightGrey: "#F5F5F5",
		grey: "#D5D5D5",
		mediumGrey: "#7A7A7A",
		darkGrey: "#54595F",

		// Raw colors
		white: "#FFFFFF",
		black: "#000000",
		lightBlack: "#313131",
		"deep-blue": ["#E9EDFC", "#C1CCF6", "#99ABF0"],
	},

	shadows: {
		// other shadows (xs, sm, lg) will be merged from default theme
		md: "1px 1px 3px rgba(0,0,0,.25)",
		xl: "5px 5px 3px rgba(0,0,0,.25)",
	},

	spacing: {
		xs: 8,
		sm: 12,
		md: 16,
		lg: 20,
		xl: 24,
		xxl: 30,
	},

	borderRadius: {
		big: 20,
	},

	// -*-*-*-*-*-*-*-*-*-*-*-*- Fonts -*-*-*-*-*-*-*-*-*-*-*-*-
	fontFamily: "Poppins",
	headings: {
		fontFamily: "Poppins, Roboto, sans-serif",
		sizes: {
			h1: { fontSize: 30 },
		},
	},
};

export default MantineTheme;
