import { Button, createStyles } from "@mantine/core";
import FileSaver from "file-saver";
import React from "react";

const useStyles = createStyles((theme) => ({
	addToContactsBtn: {
		marginTop: theme.spacing.xs,
		minHeight: 35,
		backgroundColor: theme.colors.blue,
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 400,
		fontSize: 16,
	},
}));

// This is a minimum data to generate a vcard file:
//`BEGIN:VCARD
// 	VERSION:3.0
// 	N:${lastName};${firstName};;;
// 	FN:${firstName} ${lastName}
// 	END:VCARD
// 	`;

const VcfButton = ({
	title = "title",
	emails,
	phoneNumbers,
	cellphoneNumbers,
	addresses, // TODO
	description,
	websites,
	name,
}) => {
	const { classes } = useStyles();

	const handleClick = (e) => {
		e.preventDefault();
		let vcfString = `BEGIN:VCARD
			VERSION:3.0
			N:${title};;;;
			FN:${title}${name ? "-" + name : ""}\n`;
		if (emails)
			emails.map(
				(email) => (vcfString += `EMAIL;type=INTERNET;type=pref:${email}\n`)
			);
		if (phoneNumbers)
			phoneNumbers.map(
				(phoneNumber) =>
					(vcfString += `TEL;type=CELL;type=VOICE;type=pref:${phoneNumber}\n`)
			);

		if (cellphoneNumbers)
			cellphoneNumbers.map(
				(cellphoneNumber) =>
					(vcfString += `TEL;type=WORK;type=VOICE;type=pref:${cellphoneNumber}\n`)
			);
		if (websites) websites.map((website) => (vcfString += `URL:${website}\n`));
		if (description) vcfString += `NOTE:${description}\n`;
		vcfString += `END:VCARD
			`;

		var file = new Blob([vcfString], { type: "text/vcard;charset=utf-8" });
		let a = document.createElement("a");
		a.download = `${title}.vcf`;
		a.href = URL.createObjectURL(file);
		var reader = new FileReader();
		if (navigator.userAgent.match("CriOS")) {
			reader.onloadend = (e) => {
				window.open(reader.result);
			};
			reader.readAsDataURL(file);
		} else if (navigator.userAgent.match(/iPad|iPhone|Android/i)) {
			reader.onload = (e) => {
				window.location.href = reader.result;
			};
			reader.readAsDataURL(file);
		} else {
			FileSaver.saveAs(file, `${title}.vcf`, true);
		}
	};

	return (
		<Button className={classes.addToContactsBtn} onClick={handleClick}>
			Ajouter aux contacts
		</Button>
	);
};

export default VcfButton;
