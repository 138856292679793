import PropTypes from "prop-types";
import * as React from "react";
const axios = require("axios");

const ApiContext = React.createContext();

/**
 * This context provider allows to manage api & connection state
 */
const ApiContextProvider = ({ children }) => {
	const [accessToken, setAccessToken] = React.useState();
	const [refreshToken, setRefreshToken] = React.useState();
	const [isConnected, setIsConnected] = React.useState(false);

	// Use to remove tokens on auth error or to disconnect the user
	const clearTokens = () => {
		setAccessToken(undefined);
		setRefreshToken(undefined);
		localStorage.removeItem("refreshToken");
		setIsConnected(false);
	};

	const connectUser = (email, password) =>
		new Promise((resolve, reject) =>
			api(`/oauth/tokens`, "post", {
				email,
				password,
			})
				.then((user) => {
					if (user) {
						setAccessToken(user?.data?.accessToken);
						setIsConnected(true);
						setRefreshToken(user?.data?.refreshToken);
						localStorage.setItem("refreshToken", user?.data?.refreshToken);
						resolve();
					} else {
						reject();
					}
				})
				.catch(() => {
					clearTokens();
					reject();
				})
		);

	const getAccessToken = async (refreshToken) =>
		await api(`/oauth/refresh`, "post", {
			refreshToken,
		});

	const resetUserPassword = async (email) =>
		await api(`/oauth/reset-password`, "post", {
			email,
		});

	// Get refresh token on local storage if the token exist
	React.useEffect(() => {
		const localRefreshToken = localStorage.getItem("refreshToken");
		if (localRefreshToken) {
			setRefreshToken(localRefreshToken);
		}
	}, []);

	// When we get refresh on local storage,
	// launch request to get a new access token & connect the user
	React.useEffect(() => {
		if (!isConnected && refreshToken) {
			getAccessToken(refreshToken).then((res) => {
				if (res?.data?.accessToken) {
					setAccessToken(res?.data?.accessToken);
					setIsConnected(true);
				} else {
					clearTokens();
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshToken]);

	const api = React.useCallback(
		async (urlPath, method = "get", data, headers = {}) => {
			const url = `${
				process.env.NODE_ENV === "development"
					? "http://localhost:8080"
					: "https://tapizi.ew.r.appspot.com"
			}${urlPath}`;

			// ----- LOG to test. Remove it ! -----
			console.log("api request", {
				urlPath,
				url,
				method,
				data,
				accessToken,
				env: process.env,
			});

			return await axios({
				method,
				url,
				data,
				headers: {
					Authorization: `Bearer ${accessToken}`,
					...headers,
				},
			})
				.then((response) => {
					console.log("API : ", response);
					return response;
				})
				.catch(function (error) {
					console.log("API error : ", error);
				});
		},
		[accessToken]
	);

	const value = {
		api,
		connectUser,
		disconnectUser: clearTokens,
		resetUserPassword,
		isConnected,
	};

	return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

ApiContextProvider.propTypes = {
	children: PropTypes.object.isRequired,
};

export const useApi = () => React.useContext(ApiContext);

export default ApiContextProvider;
