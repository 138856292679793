import { createStyles, Text, UnstyledButton } from "@mantine/core";

const useStyles = createStyles((theme) => ({
	primary: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: 40,
		minHeight: 40,
		padding: "0 16px",
		borderRadius: 4,
		width: "fit-content",
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 400,
		fontSize: 16,
		background: theme.colors.blue,
		color: theme.colors.white,
	},

	secondary: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: 40,
		minHeight: 40,
		padding: "0 16px",
		borderRadius: 4,
		width: "fit-content",
		textAlign: "center",
		background: theme.colorScheme === "dark" ? "#1b1c21" : "#DFE1E1",
	},

	transparent: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: 30,
		minHeight: 30,
		padding: "0 16px",
		borderRadius: 4,
		width: "fit-content",
		color: theme.colors.blue,
		textAlign: "center",
		background: "transparent",
	},

	whiteText: {
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 400,
		fontSize: 16,
		color: theme.colors.white,
	},

	redText: {
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 400,
		fontSize: 16,
		color: theme.colors.lightRedAlert,
	},

	text: {
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 400,
		fontSize: 16,
		color:
			theme.colorScheme === "dark" ? theme.colors.white : theme.colors.dark,
	},

	icon: {
		marginRight: 8,
		width: "fit-content",
		height: "fit-content",
	},
}));

const Button = ({
	secondary,
	transparent,
	children,
	icon,
	onClick,
	alert,
	...props
}) => {
	const { classes } = useStyles();

	if (secondary)
		return (
			<UnstyledButton
				className={classes.secondary}
				{...props}
				onClick={onClick}
			>
				{icon && <div className={classes.icon}>{icon}</div>}
				<Text className={classes.text}>{children}</Text>
			</UnstyledButton>
		);

	if (transparent)
		return (
			<UnstyledButton
				className={classes.transparent}
				{...props}
				onClick={onClick}
			>
				{icon && <div className={classes.icon}>{icon}</div>}
				<Text className={classes.text}>{children}</Text>
			</UnstyledButton>
		);

	if (alert)
		return (
			<UnstyledButton
				className={classes.transparent}
				{...props}
				onClick={onClick}
			>
				{icon && <div className={classes.icon}>{icon}</div>}
				<Text className={classes.redText}>{children}</Text>
			</UnstyledButton>
		);

	return (
		<UnstyledButton className={classes.primary} {...props} onClick={onClick}>
			{icon && <div className={classes.icon}>{icon}</div>}
			<Text className={classes.whiteText}>{children}</Text>
		</UnstyledButton>
	);
};

export default Button;
