import { createStyles } from "@mantine/core";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ContactFrom from "./components/ContactForm";
import Informations from "./components/Informations";
import ItemCollapser from "./components/ItemCollapser";
import SocialNetworks from "./components/SocialNetworks/SocialNetworks";
import Websites from "./components/Websites";
import PDFManager from "./components/PDFManager";
import ProfilDeletionConfirmation from "./components/ProfilDeletionConfirmation";

import BackButton from "../../components/Buttons/BackButton";
import Button from "../../components/Buttons/Button";
import Text from "../../components/Text";
import UserPicture from "../../components/UserPicture/UserPicture";
import { useProfiles } from "../../contexts/ProfilesContextProvider";
import { useTheme } from "../../contexts/ThemeContextProvider";
import { useToasts } from "../../contexts/ToastsContextProvider";
import paths from "../../paths";

import CreatedByTapizi from "../../components/CreatedByTapizi";

const BUCKET_NAME = "tapizi-v1";

const useStyles = createStyles((theme) => ({
	page: {
		display: "flex",
		flexDirection: "column",
		background: theme.colors.globalBackground,
		alignItems: "center",
		height: "100vh",
	},
	header: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%",
		padding: "0 12px",
	},
	backButtonContainer: {
		marginRight: "auto",
	},
	container: {
		display: "flex",
		flexDirection: "column",
		backgroundColor:
			theme.colorScheme === "dark"
				? theme.colors.darkBackground
				: theme.colors.lightGrey,
		alignItems: "center",
		borderTopLeftRadius: "100% 20%",
		borderTopRightRadius: "100% 20%",
		height: "100%",
		width: "100%",
		paddingLeft: theme.spacing.lg,
		paddingRight: theme.spacing.lg,
		gap: theme.spacing.lg,
		marginTop: theme.spacing.xxl * 2.5,
	},
	toBottom: {
		marginTop: "auto",
		marginBottom: theme.spacing.sm,
	},
}));

const initialSocialNetworks = {
	facebook: "",
	instagram: "",
	twitter: "",
	linkedin: "",
	youtube: "",
	twitch: "",
	tiktok: "",
	snapchat: "",
	pinterest: "",
	skype: "",
};

const initialForm = {
	title: "",
	description: "",
	addresses: [],
	phoneNumbers: [],
	cellphoneNumbers: [],
	emails: [],
	websites: [],
	socialNetworks: initialSocialNetworks,
};

const ProfilEdition = () => {
	const { classes } = useStyles();
	const {
		createProfile,
		getProfile,
		updateProfile,
		deleteProfile,
		setPdfsOfProfile,
		getPdfsNameOfProfile,
		addProfilePictureToProfile,
		getProfilePictureOfProfile,
	} = useProfiles();
	const { colorScheme } = useTheme();
	const navigate = useNavigate();
	const { state } = useLocation();
	const { addToast } = useToasts();

	const [form, setForm] = React.useState(initialForm);
	const [profilePicture, setProfilePicture] = React.useState();
	const [PDFFiles, setPDFFiles] = React.useState([]);
	const [isOpenDeleting, setIsOpenDeleting] = React.useState(false);
	// Allow to know which form component is open
	const [componentOpen, setComponentOpen] = React.useState("");

	const handleChangeForm = (field, value) =>
		setForm((oldForm) => ({ ...oldForm, [field]: value }));

	const handleChangePDFFiles = (pdfs) => setPDFFiles(pdfs);

	const handleComponentOpen = (value) =>
		setComponentOpen((oldValue) => (oldValue === value ? "" : value));

	const handleChangeProfilePicture = (image) => {
		setProfilePicture({ image, url: URL.createObjectURL(image) });
	};

	const handleSave = () => {
		if (form.title === "" || form.description === "") {
			return addToast("Les champs Titre et Description sont obligatoires", {
				appearance: "error",
			});
		}

		state?.profileId
			? updateProfile(state?.profileId, form)
					.then(({ data }) => {
						addToast("Votre profil a bien été modifié", {
							appearance: "success",
						});
						setPdfsOfProfile(data.id, PDFFiles);
						if (profilePicture.image)
							addProfilePictureToProfile(profilePicture.image, data.id);
					})
					.catch(() =>
						addToast("Vos modifications n'ont pas pu être sauvegardées", {
							appearance: "error",
						})
					)
			: createProfile(form)
					.then(({ data }) => {
						addToast("Votre profil a bien été créé", {
							appearance: "success",
						});
						setPdfsOfProfile(data, PDFFiles);
						if (profilePicture.image)
							addProfilePictureToProfile(profilePicture.image, data);
						navigate(paths.account.baseUrl);
					})
					.catch(() =>
						addToast("Votre nouveau profil n'a pas pu être sauvegarder", {
							appearance: "error",
						})
					);
	};

	const handleDelete = () => {
		if (state?.profileId) {
			deleteProfile(state?.profileId)
				.then(() => {
					addToast("Votre profil a bien été supprimé", {
						appearance: "success",
					});
					navigate(paths.account.baseUrl);
				})
				.catch(() =>
					addToast("Votre profil n'a pas pu etre supprimé", {
						appearance: "error",
					})
				);
		}
	};

	const getInitialPDFFiles = React.useCallback(
		async (profileId) => {
			setPDFFiles(await getPdfsNameOfProfile(profileId));
		},
		[getPdfsNameOfProfile]
	);

	const getInitialProfilePicture = React.useCallback(async () => {
		const initialProfilePicture = await getProfilePictureOfProfile(
			state?.profileId
		);
		if (initialProfilePicture)
			setProfilePicture({
				initialProfilePicture,
				url: `https://storage.googleapis.com/${BUCKET_NAME}/${initialProfilePicture.name}`,
			});
	}, [getProfilePictureOfProfile, state?.profileId]);

	React.useEffect(() => {
		if (state?.profileId) {
			// Get profile data
			getProfile(state.profileId).then((profile) => {
				setForm(profile.data);
			});
			// Get pdfs list
			getInitialPDFFiles(state?.profileId);
		}
	}, [state?.profileId, getProfile, getInitialPDFFiles]);

	React.useEffect(() => {
		if (state?.profileId) {
			getInitialProfilePicture();
		}
	}, [state?.profileId, getInitialProfilePicture]);

	return (
		<div className={classes.page}>
			<div className={classes.header}>
				<div className={classes.backButtonContainer}>
					<BackButton />
				</div>
				<Text variant="mainTitle">{form.title}</Text>
			</div>
			<div className={classes.container}>
				<UserPicture
					variant="header"
					handleChange={handleChangeProfilePicture}
					value={profilePicture?.url}
				/>
				<ItemCollapser
					handleOpen={() => handleComponentOpen("ContactFrom")}
					isOpen={componentOpen === "ContactFrom"}
					title="Ma fiche contact"
					icon="ContactFrom"
				>
					<ContactFrom
						title={form.title}
						description={form.description}
						addresses={form.addresses}
						handleChange={handleChangeForm}
					/>
				</ItemCollapser>
				<ItemCollapser
					handleOpen={() => handleComponentOpen("Informations")}
					isOpen={componentOpen === "Informations"}
					title="Informations principales"
					icon="Informations"
				>
					<Informations
						phoneNumbers={form.phoneNumbers}
						cellphoneNumbers={form.cellphoneNumbers}
						emails={form.emails}
						handleChange={handleChangeForm}
					/>
				</ItemCollapser>
				<ItemCollapser
					handleOpen={() => handleComponentOpen("Websites")}
					isOpen={componentOpen === "Websites"}
					title={form.websites.length > 1 ? "Sites internet" : "Site internet"}
					icon="Websites"
				>
					<Websites websites={form.websites} handleChange={handleChangeForm} />
				</ItemCollapser>
				<ItemCollapser
					handleOpen={() => handleComponentOpen("SocialNetworks")}
					isOpen={componentOpen === "SocialNetworks"}
					title="Réseaux sociaux"
					icon="SocialNetworks"
				>
					<SocialNetworks
						socialNetworks={form.socialNetworks}
						handleChange={handleChangeForm}
					/>
				</ItemCollapser>
				<ItemCollapser
					handleOpen={() => handleComponentOpen("PDF")}
					isOpen={componentOpen === "PDF"}
					title="Vos PDF"
					icon="PDF"
				>
					<PDFManager PDFFiles={PDFFiles} handleChange={handleChangePDFFiles} />
				</ItemCollapser>
				<Button onClick={handleSave} secondary>
					Sauvegarder
				</Button>
				{state?.profileId && (
					<Button onClick={() => setIsOpenDeleting(true)} alert>
						Supprimer le profil
					</Button>
				)}
				<ProfilDeletionConfirmation
					isOpen={isOpenDeleting}
					onClose={() => setIsOpenDeleting(false)}
					onConfirm={handleDelete}
				/>
				<CreatedByTapizi
					textColor={colorScheme === "dark" ? "white" : "black"}
					color={colorScheme === "dark" ? "white" : "blue"}
				/>
			</div>
		</div>
	);
};

export default ProfilEdition;
