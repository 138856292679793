import PropTypes from "prop-types";
import * as React from "react";
import { useApi } from "./ApiContextProvider";

const PDF_FOLDER_PATH = "pdf/";

const ProfilesContext = React.createContext();

/**
 * This context provider allows to manage profiles of the user
 */
const ProfilesContextProvider = ({ children }) => {
	const { api, isConnected } = useApi();

	const [summaryProfiles, setSummaryProfiles] = React.useState([]);

	// ---------- Profiles functions ------------

	const getSummaryProfiles = React.useCallback(
		async () => await api(`/profiles`, "get"),
		[api]
	);

	const getProfile = React.useCallback(
		async (id) => await api(`/profiles/id?id=${id}`, "get"),
		[api]
	);

	const getProfileByAccessoryId = React.useCallback(
		async (id) => await api(`/public-profile?id=${id}`, "get"),
		[api]
	);

	const createProfile = React.useCallback(
		async (data) => {
			const newProfile = await api(`/profiles`, "post", data);
			getSummaryProfiles().then((res) => {
				setSummaryProfiles(res.data);
			});
			return Promise.resolve(newProfile);
		},
		[api, getSummaryProfiles]
	);

	const updateProfile = React.useCallback(
		async (id, data) =>
			await api(`/profiles`, "put", {
				id,
				data,
			}),
		[api]
	);

	const deleteProfile = React.useCallback(
		async (id) =>
			await api(`/profiles`, "delete", { id })
				.then(() => {
					setSummaryProfiles((oldValue) =>
						oldValue.filter((profile) => profile.id !== id)
					);
					return Promise.resolve(id);
				})
				.catch(() => Promise.reject()),
		[api]
	);

	// ------------ Pdf functions ---------------

	const addPdfToProfile = React.useCallback(
		async (file, profileId) => {
			if (profileId && file.type === "application/pdf") {
				let formData = new FormData();
				formData.append("file", file);
				formData.append("profileId", profileId);

				await api(`/pdf`, "post", formData)
					.then(() => {
						return Promise.resolve();
					})
					.catch(() => Promise.reject());
			}
		},
		[api]
	);

	const getPdfsOfProfile = React.useCallback(
		async (profileId) => {
			if (!profileId) return [];

			const result = await api(`/pdf-public/profile-id?id=${profileId}`, "get");
			return result.data;
		},
		[api]
	);

	const getPdfsNameOfProfile = React.useCallback(
		async (profileId) => {
			const prefix = `${PDF_FOLDER_PATH}${profileId}-`;

			const result = await getPdfsOfProfile(profileId);
			const files = result.map((f) => f.name.replace(prefix, ""));
			return files;
		},
		[getPdfsOfProfile]
	);

	const deletePdfOfProfile = React.useCallback(
		async (profileId, filename) =>
			await api(`/pdf`, "delete", { profileId, filename })
				.then(() => Promise.resolve(profileId))
				.catch(() => Promise.reject()),
		[api]
	);

	const setPdfsOfProfile = React.useCallback(
		async (profileId, pdfs) => {
			const prefix = `${PDF_FOLDER_PATH}${profileId}-`;

			// First part: Deleted clouded pdf removed by user
			const pdfsInCloudStorage = await getPdfsOfProfile(profileId, pdfs);
			const oldPdfNotRemoved = pdfs.filter((pdf) => typeof pdf === "string");

			const pdfToRemove = pdfsInCloudStorage.filter((pdf) => {
				return !oldPdfNotRemoved.includes(pdf.name.replace(prefix, ""));
			});
			pdfToRemove.map((pdfToRemove) =>
				deletePdfOfProfile(profileId, pdfToRemove)
			);

			// Second part: Add the news pdf
			const newPdfs = pdfs.filter((pdf) => typeof pdf !== "string");
			newPdfs.map((newPdf) => addPdfToProfile(newPdf, profileId));
		},
		[getPdfsOfProfile, addPdfToProfile, deletePdfOfProfile]
	);

	// -------- Profil picture functions --------

	const addProfilePictureToProfile = React.useCallback(
		async (file, profileId) => {
			if (
				profileId &&
				(file.type === "image/png" || file.type === "image/jpeg")
			) {
				let formData = new FormData();
				formData.append("file", file);
				formData.append("profileId", profileId);

				// TODO add security to not add picture if is same as previous

				await api(`/profile-picture`, "post", formData)
					.then(() => Promise.resolve())
					.catch(() => Promise.reject());
			}
		},
		[api]
	);

	const getProfilePictureOfProfile = React.useCallback(
		async (profileId) => {
			if (!profileId) return undefined;

			const result = await api(
				`/profile-picture-public/id?id=${profileId}`,
				"get"
			);

			return result.data;
		},
		[api]
	);

	React.useEffect(() => {
		// On user connection,
		// Get summary list of user profiles

		if (isConnected) {
			getSummaryProfiles().then((res) => {
				setSummaryProfiles(res.data);
			});
		}
	}, [isConnected, getSummaryProfiles]);

	const value = {
		summaryProfiles,
		// ---------- Profiles functions ------------
		getSummaryProfiles,
		getProfile,
		getProfileByAccessoryId,
		createProfile,
		updateProfile,
		deleteProfile,
		// ------------ Pdf functions ---------------
		addPdfToProfile,
		getPdfsOfProfile,
		getPdfsNameOfProfile,
		setPdfsOfProfile,
		deletePdfOfProfile,
		// -------- Profil picture functions --------
		addProfilePictureToProfile,
		getProfilePictureOfProfile,
	};

	return (
		<ProfilesContext.Provider value={value}>
			{children}
		</ProfilesContext.Provider>
	);
};

ProfilesContextProvider.propTypes = {
	children: PropTypes.object.isRequired,
};

export const useProfiles = () => React.useContext(ProfilesContext);

export default ProfilesContextProvider;
