import { createStyles, Paper } from "@mantine/core";
import React from "react";
import { BsPersonBadge } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import { RiArrowUpSLine, RiContactsBookLine } from "react-icons/ri";
import { IoMdPeople } from "react-icons/io";
import { FaLink } from "react-icons/fa";
import { VscFilePdf } from "react-icons/vsc";

import Text from "../../../components/Text";

const useStyles = createStyles((theme) => ({
	paper: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
	},
	header: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: theme.spacing.sm,
	},
	icon: {
		height: theme.spacing.lg,
		width: theme.spacing.lg,
	},
}));

const ItemCollapser = ({ handleOpen, isOpen, children, title, icon }) => {
	const { classes } = useStyles();

	return (
		<Paper
			className={classes.paper}
			shadow="xs"
			p="md"
			style={{ height: isOpen ? "fit-content" : 60 }}
		>
			<div className={classes.header} onClick={handleOpen}>
				{icon === "ContactFrom" && (
					<RiContactsBookLine className={classes.icon} />
				)}
				{icon === "Informations" && <BsPersonBadge className={classes.icon} />}
				{icon === "Websites" && <FaLink className={classes.icon} />}
				{icon === "SocialNetworks" && <IoMdPeople className={classes.icon} />}
				{icon === "PDF" && <VscFilePdf className={classes.icon} />}
				<Text>{title}</Text>
				{isOpen ? <RiArrowUpSLine /> : <IoIosArrowDown />}
			</div>
			{isOpen && <div>{children}</div>}
		</Paper>
	);
};

export default ItemCollapser;
