import PropTypes from "prop-types";
import * as React from "react";
import { useApi } from "./ApiContextProvider";

const AccessoriesContext = React.createContext();

/**
 * This context provider allows to manage accessories of the user
 */
const AccessoriesContextProvider = ({ children }) => {
	const { api, isConnected } = useApi();

	const [accessories, setAccessories] = React.useState([]);

	const getAccessories = React.useCallback(
		async () => await api(`/accessories`, "get"),
		[api]
	);

	const getAccessoriesKeyById = React.useCallback(
		async (id) => await api(`/accessories-keys/id?id=${id}`, "get"),
		[api]
	);

	const createAccessory = React.useCallback(
		async (data) => {
			const newAccessories = await api(`/accessories`, "post", data);
			getAccessories().then((res) => {
				setAccessories(res.data);
			});
			return Promise.resolve(newAccessories);
		},
		[api, getAccessories]
	);

	const updateAccessory = React.useCallback(
		async (id, data) => {
			try {
				const result = await api(`/accessories`, "put", {
					id,
					data,
				});
				setAccessories((oldAccessories) =>
					oldAccessories.map((el) => {
						if (el.id !== id) {
							return el;
						} else {
							return result.data;
						}
					})
				);
				return Promise.resolve(result);
			} catch {
				return Promise.reject();
			}
		},
		[api]
	);

	React.useEffect(() => {
		// On user connection,
		// Get list of user accessories

		if (isConnected) {
			getAccessories().then((res) => {
				setAccessories(res.data);
			});
		}
	}, [isConnected, getAccessories]);

	const value = {
		accessories,
		// Accesories
		getAccessories,
		createAccessory,
		updateAccessory,
		// Accesories keys
		getAccessoriesKeyById,
	};

	return (
		<AccessoriesContext.Provider value={value}>
			{children}
		</AccessoriesContext.Provider>
	);
};

AccessoriesContextProvider.propTypes = {
	children: PropTypes.object.isRequired,
};

export const useAccessories = () => React.useContext(AccessoriesContext);

export default AccessoriesContextProvider;
