import { createStyles } from "@mantine/core";

import Text from "../components/Text";

const useStyles = createStyles((theme) => ({
	link: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		maxHeight: 40,
		maxWidth: "100vw",
		textDecoration: "none",
		marginTop: "auto",
		marginBottom: theme.spacing.xs,
	},
	logo: {
		height: 100,
		marginLeft: theme.spacing.xs / 2,
	},
}));

const CreatedByTapizi = ({ textColor, color }) => {
	const { classes } = useStyles();

	return (
		<a
			className={classes.link}
			href="https://www.tapizi.com"
			target="_blank"
			rel="noreferrer"
		>
			<Text color={textColor === "black" ? "#000000" : "#ffffff"}>
				Créé par
			</Text>
			<img
				src={
					color === "white"
						? "img/logo_tapizi_white.svg"
						: color === "blue"
						? "img/logo_tapizi_blue.svg"
						: "img/logo_tapizi_pink.svg"
				}
				alt="tapizi_logo"
				className={classes.logo}
			/>
		</a>
	);
};

export default CreatedByTapizi;
