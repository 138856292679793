import { createStyles, Text as MantineText } from "@mantine/core";
import React from "react";
import { IoIosSettings } from "react-icons/io";
import { useNavigate, useSearchParams } from "react-router-dom";

import ConnexionButton from "../../components/Buttons/ConnexionButton";
import CreatedByTapizi from "../../components/CreatedByTapizi";
import Loading from "../../components/Loading";
import NotFound from "../../components/NotFound";
import SocialShareButtons from "../../components/SocialShareButtons/SocialShareButtons";
import Text from "../../components/Text";
import UserPicture, {
	AVATAR_HEIGHT,
} from "../../components/UserPicture/UserPicture";
import { useApi } from "../../contexts/ApiContextProvider";
import { useProfiles } from "../../contexts/ProfilesContextProvider";
import { useUser } from "../../contexts/UserContextProvider";
import { useTheme } from "../../contexts/ThemeContextProvider";
import Informations from "./components/Informations";
import VcfButton from "./components/VcfButton";

import paths from "../../paths";

const BUCKET_NAME = "tapizi-v1";

const useStyles = createStyles((theme) => ({
	redirection: {
		margin: "12px 12px 0px auto",
		color: theme.colorScheme === "dark" ? "#F5F5F5" : theme.colors.lightBlack,
	},
	page: {
		display: "flex",
		flexDirection: "column",
		background: theme.colors.globalBackground,
		alignItems: "center",
		height: "100vh",
	},
	container: {
		display: "flex",
		flexDirection: "column",
		backgroundColor:
			theme.colorScheme === "dark"
				? theme.colors.darkBackground
				: theme.colors.lightGrey,
		alignItems: "center",
		borderTopLeftRadius: "100% 20%",
		borderTopRightRadius: "100% 20%",
		height: "100%",
		width: "100%",
		marginTop: theme.spacing.xxl * 3,
	},
	description: {
		position: "relative",
		top: -AVATAR_HEIGHT / 2 + theme.spacing.md * 1.5,
		maxWidth: "90%",
		textAlign: "center",
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 400,
		fontSize: 16,
	},
	addToContactsBtn: {
		marginTop: theme.spacing.xs,
		minHeight: 35,
		backgroundColor: theme.colors.blue,
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 400,
		fontSize: 16,
	},
	footer: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		alignItems: "center",
		gap: theme.spacing.sm,
	},
}));

const ProfilPage = () => {
	const { classes } = useStyles();
	const { isConnected } = useApi();
	const {
		getProfileByAccessoryId,
		getPdfsOfProfile,
		getProfilePictureOfProfile,
	} = useProfiles();
	const { user } = useUser();
	const navigate = useNavigate();
	const { colorScheme } = useTheme();

	const [searchParams] = useSearchParams();
	const [isLoading, setIsLoading] = React.useState(true);
	const [isError, setIsError] = React.useState(false);

	const [profile, setProfile] = React.useState({});
	const [profilePdfs, setProfilePdfs] = React.useState([]);
	const [profilePicture, setProfilePicture] = React.useState();

	const [isDescriptionDisplayFull, setIsDescriptionDisplayFull] =
		React.useState(false);

	const getInitialProfilePicture = React.useCallback(async () => {
		const initialProfilePicture = await getProfilePictureOfProfile(profile.id);
		if (initialProfilePicture)
			setProfilePicture({
				initialProfilePicture,
				url: `https://storage.googleapis.com/${BUCKET_NAME}/${initialProfilePicture.name}`,
			});
	}, [getProfilePictureOfProfile, profile.id]);

	React.useEffect(() => {
		if (profile.id) {
			getInitialProfilePicture();
		}
	}, [profile.id, getInitialProfilePicture]);

	React.useEffect(() => {
		const accessoryId = searchParams.get("id");
		if (accessoryId) {
			getProfileByAccessoryId(accessoryId)
				.then((profile) => {
					setIsLoading(false);
					if (profile) {
						setProfile(profile.data);
					} else {
						if (!isConnected) {
							navigate(paths.login.baseUrl, {
								state: { accessoryIdToCreate: accessoryId },
							});
						} else {
							navigate(paths.accessoryEdition.baseUrl, {
								state: { accessoryIdToCreate: accessoryId },
							});
						}
					}
				})
				.catch((err) => {
					setIsError(true);
				});
		} else {
			setIsError(true);
		}
	}, [searchParams, getProfileByAccessoryId, navigate, isConnected]);

	const getPdfs = React.useCallback(
		(profileId) => {
			const prefix = `pdf/${profile.id}-`;

			return getPdfsOfProfile(profileId).then((pdfs) =>
				setProfilePdfs(
					pdfs?.map((pdf) => ({
						link: `https://storage.googleapis.com/${BUCKET_NAME}/${pdf.name}`,
						text: pdf.name.replace(prefix, ""),
					}))
				)
			);
		},
		[getPdfsOfProfile, profile]
	);

	React.useEffect(() => {
		if (profile) getPdfs(profile.id);
	}, [getPdfs, profile]);

	if (isError) return <NotFound />;
	else
		return (
			<>
				{!isLoading ? (
					<div
						className={classes.page}
						style={{ paddingTop: isConnected ? 0 : 40 }}
					>
						{isConnected && (
							<IoIosSettings
								size={36}
								className={classes.redirection}
								onClick={() => navigate(paths.account.baseUrl)}
							/>
						)}
						<Text
							sx={(theme) => ({
								marginTop: isConnected ? theme.spacing.md : theme.spacing.xl,
							})}
							variant="mainTitle"
						>
							{`${profile.title}`}
						</Text>
						<div className={classes.container}>
							<UserPicture variant="header" value={profilePicture?.url} />
							{profile.description && (
								<div className={classes.description}>
									{isDescriptionDisplayFull ? (
										<MantineText
											onClick={() => setIsDescriptionDisplayFull(false)}
										>
											{profile.description}
										</MantineText>
									) : (
										<MantineText
											lineClamp={3}
											onClick={() => setIsDescriptionDisplayFull(true)}
										>
											{profile.description}
										</MantineText>
									)}
								</div>
							)}

							<SocialShareButtons
								urlFacebook={profile.urlFacebook}
								urlInstagram={profile.urlInstagram}
								urlTwitter={profile.urlTwitter}
								urlLinkedIn={profile.urlLinkedIn}
								urlTiktok={profile.urlTiktok}
								urlPinterest={profile.urlPinterest}
								urlSnapchat={profile.urlSnapchat}
								urlSkype={profile.urlSkype}
							/>
							<div className={classes.footer}>
								<VcfButton
									title={profile.title}
									emails={profile?.emails}
									phoneNumbers={profile?.phoneNumbers}
									cellphoneNumbers={profile?.cellphoneNumbers}
									addresses={profile?.addresses}
									description={profile?.description}
									websites={profile?.websites}
									name={user?.name}
								/>
								<Informations
									phoneNumbers={profile.phoneNumbers}
									cellphoneNumbers={profile.cellphoneNumbers}
									mails={profile.mails}
									websites={profile.websites}
									pdfs={profilePdfs}
									addresses={profile.addresses}
								/>
								<ConnexionButton withBackButton={true} />
							</div>
							<CreatedByTapizi
								textColor={colorScheme === "dark" ? "white" : "black"}
								color={colorScheme === "dark" ? "white" : "blue"}
							/>
						</div>
					</div>
				) : (
					<Loading />
				)}
			</>
		);
};

export default ProfilPage;
