import { Button } from "@mantine/core";
import { BsTwitter, BsFacebook, BsInstagram, BsLinkedin } from "react-icons/bs";
import { BiShare } from "react-icons/bi";
import {
	FaTiktok,
	FaYoutube,
	FaTwitch,
	FaPinterest,
	FaSkype,
} from "react-icons/fa";
import { RiSnapchatLine } from "react-icons/ri";

const ICON_SIZE = 25;

const SocialButton = ({ url, socialNetwork = "twitter" }) => {
	const getSocialIcon = () => {
		if (socialNetwork === "twitter") {
			return <BsTwitter size={ICON_SIZE} />;
		} else if (socialNetwork === "facebook") {
			return <BsFacebook size={ICON_SIZE} />;
		} else if (socialNetwork === "instagram") {
			return <BsInstagram size={ICON_SIZE} />;
		} else if (socialNetwork === "linkedin") {
			return <BsLinkedin size={ICON_SIZE} />;
		} else if (socialNetwork === "tiktok") {
			return <FaTiktok size={ICON_SIZE} />;
		} else if (socialNetwork === "youtube") {
			return <FaYoutube size={ICON_SIZE} />;
		} else if (socialNetwork === "twitch") {
			return <FaTwitch size={ICON_SIZE} />;
		} else if (socialNetwork === "snapchat") {
			return <RiSnapchatLine size={ICON_SIZE} />;
		} else if (socialNetwork === "pinterest") {
			return <FaPinterest size={ICON_SIZE} />;
		} else if (socialNetwork === "skype") {
			return <FaSkype size={ICON_SIZE} />;
		} else {
			return <BiShare size={ICON_SIZE} />;
		}
	};

	const colorButton =
		socialNetwork === "facebook" ||
		socialNetwork === "twitter" ||
		socialNetwork === "tiktok" ||
		socialNetwork === "linkedin" ||
		socialNetwork === "snapchat" ||
		socialNetwork === "pinterest" ||
		socialNetwork === "skype" ||
		socialNetwork === "youtube" ||
		socialNetwork === "twitch"
			? socialNetwork
			: "black";

	const verifiedUrl =
		url.includes("http://") || url.includes("https://") ? url : "http://" + url;

	return (
		<Button
			component="a"
			target="_blank"
			rel="noopener noreferrer"
			href={verifiedUrl}
			leftIcon={getSocialIcon()}
			styles={(theme) => ({
				root: {
					backgroundColor:
						socialNetwork === "instagram" ? "" : theme.colors[colorButton],
					background:
						socialNetwork === "instagram"
							? "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)"
							: "",
					border: 0,
					padding: 0,
					height: 48,
					width: 48,
					opacity: url === "" ? 0.4 : 1,

					"&:hover": {
						backgroundColor: theme.fn.darken(theme.colors[colorButton], 0.05),
					},
				},
				leftIcon: {
					marginRight: 0,
				},
			})}
		/>
	);
};

export default SocialButton;
