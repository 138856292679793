import { createStyles } from "@mantine/core";
import React from "react";
import { AiOutlinePlus } from "react-icons/ai";

import Button from "./Button";
import Text from "../Text";

const useStyles = createStyles((theme) => ({
	button: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-start",
		marginTop: theme.spacing.sm,
		width: "100%",
	},
	plusIcon: {
		marginTop: 3,
		strokeWidth: 25,
	},
}));

const AddButton = ({ onClick, label }) => {
	const { classes } = useStyles();

	return (
		<Button
			className={classes.button}
			transparent
			onClick={onClick}
			icon={<AiOutlinePlus className={classes.plusIcon} />}
		>
			<Text>{label}</Text>
		</Button>
	);
};

export default AddButton;
