import { createStyles, FileInput } from "@mantine/core";
import React from "react";
import { IoIosClose } from "react-icons/io";

import { useToasts } from "../../../contexts/ToastsContextProvider";

const useStyles = createStyles((theme) => ({
	input: {
		width: "100%",
		marginBottom: theme.spacing.sm,
	},
	list: {
		marginBottom: theme.spacing.sm,
	},
	itemList: {
		display: "flex",
		alignItems: "center",
		paddingTop: theme.spacing.xs,
		paddingBottom: theme.spacing.xs,
	},
	text: {
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden",
		margin: 0,
		width: "80%",
	},
	addInput: {
		marginLeft: theme.spacing.xl,
		marginRight: theme.spacing.xl,
	},
	closeIcon: {
		height: theme.spacing.xl,
		width: theme.spacing.xl,
		marginRight: theme.spacing.sm,
	},
}));

// Pdf size max is 2.5 Mo
const PDF_SIZE_MAX = 2.5 * 1000000;

const PDFManager = ({ handleChange, PDFFiles }) => {
	const { classes } = useStyles();
	const { addToast } = useToasts();

	const addPDF = (file) => {
		if (file?.type === "application/pdf" && file?.size < PDF_SIZE_MAX) {
			handleChange((oldFiles) => [...oldFiles, file]);
		} else if (file?.ty0pe !== "application/pdf" && file?.type) {
			addToast("Merci de vérifier que votre fichier est au format PDF", {
				appearance: "error",
			});
		} else if (file?.size >= PDF_SIZE_MAX) {
			addToast("Votre PDF est trop volumineux (2,5 Mo maximum)", {
				appearance: "error",
			});
		}
	};

	const removePDF = (idx) =>
		handleChange((oldFiles) => oldFiles.filter((_, i) => idx !== i));

	return (
		<div className={classes.form}>
			<div className={classes.list}>
				{PDFFiles.map((pdf, idx) => {
					return (
						<div key={`pdf_${idx}`} className={classes.itemList}>
							<IoIosClose
								className={classes.closeIcon}
								onClick={() => {
									removePDF(idx);
								}}
							/>
							<p className={classes.text}>
								{typeof pdf === "string" ? pdf : pdf.name}
							</p>
						</div>
					);
				})}
			</div>
			<FileInput
				className={classes.addInput}
				radius="xl"
				size="xs"
				value={{ name: "Cliquer pour ajouter un PDF" }}
				onChange={(file) => {
					addPDF(file);
				}}
			/>
		</div>
	);
};

export default PDFManager;
