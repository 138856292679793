import { UnstyledButton, createStyles } from "@mantine/core";
import { MdPictureAsPdf } from "react-icons/md";
import { FiMail } from "react-icons/fi";
import { RiSafariLine } from "react-icons/ri";
import { TiPhoneOutline } from "react-icons/ti";
import { MdOutlineHomeWork } from "react-icons/md";
import { GiRotaryPhone } from "react-icons/gi";

import getPhoneType from "../../../utils/getPhoneType";
import cutBeginningUrl from "../../../utils/cutBeginningUrl";

import Text from "../../../components/Text";

const ICON_SIZE = 21;

const useStyles = createStyles((theme) => ({
	button: {
		display: "flex",
		flexDirection: "row",
		height: 40,
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		paddingLeft: theme.spacing.md,
		paddingRight: theme.spacing.md,
		textAlign: "left",
		background:
			theme.colorScheme === "dark"
				? theme.colors.lightBlack
				: theme.colors.white,
		borderRadius: theme.spacing.xs / 2,
		gap: theme.spacing.sm,
	},
	link: {
		textDecoration: "none",
		color:
			theme.colorScheme === "dark" ? theme.colors.white : theme.colors.black,
		maxWidth: "100%",
	},
}));

const InformationButton = ({ link, text, icon }) => {
	const { classes } = useStyles();

	const getIcon = () => {
		if (icon === "phone")
			return (
				<GiRotaryPhone
					size={ICON_SIZE}
					color="#54595F"
					style={{ minWidth: ICON_SIZE }}
				/>
			);
		else if (icon === "mobile")
			return (
				<TiPhoneOutline
					size={ICON_SIZE}
					color="#54595F"
					style={{ minWidth: ICON_SIZE }}
				/>
			);
		else if (icon === "mail")
			return (
				<FiMail
					size={ICON_SIZE}
					color="#54595F"
					style={{ minWidth: ICON_SIZE }}
				/>
			);
		else if (icon === "web")
			return (
				<RiSafariLine
					size={ICON_SIZE}
					color="#54595F"
					style={{ minWidth: ICON_SIZE }}
				/>
			);
		else if (icon === "pdf")
			return (
				<MdPictureAsPdf
					size={ICON_SIZE}
					color="#54595F"
					style={{ minWidth: ICON_SIZE }}
				/>
			);
		else if (icon === "address")
			return (
				<MdOutlineHomeWork
					size={ICON_SIZE + 20}
					color="#54595F"
					style={{ minWidth: ICON_SIZE }}
				/>
			);
		else return <></>;
	};

	const mapsSelector = (text) => {
		if (
			/* if we're on iOS, open in Apple Maps */
			getPhoneType() === "iPhone" ||
			getPhoneType() === "iPad" ||
			getPhoneType() === "iPod"
		)
			window.open(`maps://maps.google.com/maps?daddr=${text}&amp;ll=`);
		else window.open(`https://maps.google.com/maps?daddr=${text}&amp;ll=`);
	};

	return (
		<UnstyledButton
			className={classes.button}
			onClick={() => {
				if (icon === "address") mapsSelector(text);
				else return null;
			}}
		>
			{getIcon()}
			{(icon === "phone" || icon === "mobile") && (
				<a className={classes.link} href={`tel:${text}`}>
					<Text variant="overflowText">{text}</Text>
				</a>
			)}
			{icon === "mail" && (
				<a
					className={classes.link}
					href={`mailto:${text}`}
					target="_blank"
					rel="noreferrer"
				>
					<Text variant="overflowText">{text}</Text>
				</a>
			)}
			{(icon === "web" || icon === "pdf") && (
				<a
					className={classes.link}
					href={link}
					target="_blank"
					rel="noreferrer"
				>
					<Text variant="overflowText">{cutBeginningUrl(text)}</Text>
				</a>
			)}
			{icon === "address" && <Text variant="overflowText">{text}</Text>}
		</UnstyledButton>
	);
};

export default InformationButton;
