import { createStyles } from "@mantine/core";
import SocialButton from "./SocialButton";

const useStyles = createStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		maxWidth: 304,
		gap: theme.spacing.md,
		marginRight: "auto",
		marginLeft: "auto",
	},
}));

const SocialShareButtons = ({
	urlFacebook,
	urlInstagram,
	urlTwitter,
	urlLinkedIn,
	urlTiktok,
	urlYoutube,
	urlTwitch,
	urlSnapchat,
	urlPinterest,
	urlSkype,
}) => {
	const { classes } = useStyles();

	return (
		<div className={classes.container}>
			{urlFacebook && (
				<SocialButton url={urlFacebook} socialNetwork="facebook" />
			)}
			{urlInstagram && (
				<SocialButton url={urlInstagram} socialNetwork="instagram" />
			)}
			{urlTwitter && <SocialButton url={urlTwitter} socialNetwork="twitter" />}
			{urlLinkedIn && (
				<SocialButton url={urlLinkedIn} socialNetwork="linkedin" />
			)}
			{urlTiktok && <SocialButton url={urlTiktok} socialNetwork="tiktok" />}
			{urlYoutube && <SocialButton url={urlYoutube} socialNetwork="youtube" />}
			{urlTwitch && <SocialButton url={urlTwitch} socialNetwork="twitch" />}
			{urlSnapchat && (
				<SocialButton url={urlSnapchat} socialNetwork="snapchat" />
			)}
			{urlPinterest && (
				<SocialButton url={urlPinterest} socialNetwork="pinterest" />
			)}
			{urlSkype && <SocialButton url={urlSkype} socialNetwork="skype" />}
		</div>
	);
};

export default SocialShareButtons;
