const paths = {
	account: {
		baseUrl: "/account",
	},
	profilEdition: {
		baseUrl: "/profil-edition",
	},
	login: {
		baseUrl: "/login",
	},
	accessoryEdition: {
		baseUrl: "/create-an-accessory",
	},
	profil: {
		baseUrl: "/",
	},
};

export default paths;
