import { createStyles } from "@mantine/core";
import React from "react";
import SocialNetwork from "./components/SocialNetwork";

const useStyles = createStyles((theme) => ({
	page: {
		display: "flex",
		flexDirection: "column",
		background: theme.colors.globalBackground,
		alignItems: "center",
		height: "100vh",
	},
	container: {
		display: "flex",
		flexDirection: "column",
		backgroundColor:
			theme.colorScheme === "dark"
				? theme.colors.darkBackground
				: theme.colors.lightBackground,
		alignItems: "center",
		borderTopLeftRadius: "100% 20%",
		borderTopRightRadius: "100% 20%",
		height: "100%",
		width: "100%",
		padding: theme.spacing.lg,
		gap: theme.spacing.lg,
	},
	input: {
		width: "100%",
		marginBottom: theme.spacing.sm,
	},
	email: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	closeIcon: {
		height: theme.spacing.xl,
		width: theme.spacing.xl,
		marginRight: theme.spacing.sm,
	},
	plusIcon: {
		marginRight: theme.spacing.xs,
	},
	toBottom: {
		marginTop: "auto",
	},
}));

const ProfilEdition = ({
	handleChange,
	handleOpen,
	isOpen,
	socialNetworks,
}) => {
	const { classes } = useStyles();

	const updateSocialNetwork = (socialNetworksName, value) => {
		const newSocialNetworks = {
			...socialNetworks,
			[socialNetworksName]: value,
		};
		handleChange("socialNetworks", newSocialNetworks);
	};

	return (
		<div className={classes.form}>
			{Object.keys(socialNetworks).map((socialNetwork, index) => (
				<SocialNetwork
					socialNetwork={socialNetwork}
					key={socialNetwork}
					index={index}
					url={socialNetworks[socialNetwork]}
					updateSocialNetwork={updateSocialNetwork}
				/>
			))}
		</div>
	);
};

export default ProfilEdition;
