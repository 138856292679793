import { createStyles, Modal as MantineModal, Text } from "@mantine/core";

import Button from "../../../components/Buttons/Button";

const useStyles = createStyles((theme) => ({
	header: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%",
		height: theme.spacing.xl,
		marginBottom: theme.spacing.lg,
	},
	footer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%",
		marginTop: theme.spacing.xl,
	},
}));

const ProfilDeletionConfirmation = ({ isOpen, onClose, onConfirm }) => {
	const { classes } = useStyles();

	return (
		<MantineModal
			centered
			opened={isOpen}
			onClose={onClose}
			withCloseButton={false}
		>
			<>
				<div className={classes.header}>
					<Text size="md" weight={600}>
						Suppression d'un profil
					</Text>
				</div>
				<Text>Etes vous sûr de vouloir supprimer ce profil ?</Text>
				<div className={classes.footer}>
					<Button secondary onClick={() => onClose()}>
						Annuler
					</Button>
					<Button onClick={onConfirm}>Confirmer</Button>
				</div>
			</>
		</MantineModal>
	);
};
export default ProfilDeletionConfirmation;
