import PropTypes from "prop-types";
import React, { useState } from "react";
import { Toast } from "../components/Toasts";
import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
	paper: {
		top: 50,
		right: 10,
		position: "fixed",
		zIndex: 2000,
		gap: 20,
	},
}));

const ToastContext = React.createContext();

const DEFAULT_DURATION = 3000;

let TOAST_COUNTER = 42; // magic number that will be used as id

const AddToastProvider = ({ children }) => {
	const { classes } = useStyles();

	const [toasts, setToasts] = useState([]);

	// Reset notification params
	const resetNotification = (id) => {
		setToasts((oldToasts) =>
			oldToasts.reduce((acc, toast) => {
				if (toast.id === id) return [...acc, { ...toast, isOpen: false }];
				else return [...acc, toast];
			}, [])
		);
		const timer = setTimeout(() => {
			setToasts((oldToast) => oldToast.filter((toast) => toast.id !== id));
		}, 500);
		return () => clearTimeout(timer);
	};

	// Close notifications after duration define or 3 secondes (default duration)
	const closeNotificationAfterDuration = (id, autoDismissTimeout) => {
		const timer = setTimeout(() => resetNotification(id), autoDismissTimeout);
		return () => clearTimeout(timer);
	};

	// Toast creation
	const addToast = (text, options) => {
		const autoDismissTimeout = options.autoDismissTimeout
			? options.autoDismissTimeout
			: DEFAULT_DURATION;

		const id = TOAST_COUNTER++;

		if (options) {
			closeNotificationAfterDuration(id, autoDismissTimeout);
		}

		setToasts((oldToasts) => [
			...oldToasts,
			{
				id: id,
				text: text,
				isOpen: true,
				options: {
					autoDismissTimeout: autoDismissTimeout,
					appearance: options.appearance,
					pathname: options?.pathname,
				},
			},
		]);
	};

	const value = {
		addToast,
	};

	return (
		<ToastContext.Provider value={value}>
			{children}
			<div className={classes.paper}>
				{toasts.map((toast) => (
					<Toast
						key={toast.id}
						isOpen={toast.isOpen}
						text={toast.text}
						appearance={toast.options.appearance}
						path={toast.options?.pathname}
						onClose={() => resetNotification(toast.id)}
					/>
				))}
			</div>
		</ToastContext.Provider>
	);
};

AddToastProvider.propTypes = {
	children: PropTypes.object.isRequired,
};

export const useToasts = () => React.useContext(ToastContext);

export default AddToastProvider;
