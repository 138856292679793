import React from "react";
import { createStyles, Transition } from "@mantine/core";
import { MdOutlineCancel } from "react-icons/md";
import theme from "../theme/theme";

const useStyles = createStyles((theme) => ({
	container: {
		width: 340,
		marginTop: 20,
	},
	alert: {
		backgroundColor: theme.colors.white,
		boxShadow:
			"0px 2px 16px rgba(0, 0, 0, 0.24), 0px 2px 2px rgba(0, 0, 0, 0.12)",
		minHeight: 40,
		maxHeight: 60,
		borderRadius: 4,
		display: "flex",
		width: "100%",
		height: "100%",
	},
	severityIdentificator: {
		width: 16,
		minHeight: 40,
		maxHeight: 60,
		bottom: 50,
		marginRight: 15,
		borderRadius: "4px 0 0 4px",
	},
	content: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "8px 9px 8px 0px",
		width: "100%",
	},
	iconButton: {
		paddingTop: 7,
		marginLeft: 5,
	},
	icon: {
		height: 20,
		width: 20,
		color: theme.colors.black,
	},
}));

export function Toast({ isOpen, text, appearance, onClose }) {
	const { classes } = useStyles();

	const defineSeverityColor = () => {
		if (appearance === "success") return theme.colors.success;
		else if (appearance === "error") return theme.colors.redAlert;
		else return theme.colors.blue;
	};

	return (
		<div className={classes.container}>
			<Transition
				mounted={isOpen}
				transition="slide-left"
				duration={400}
				timingFunction="ease"
			>
				{() => (
					<div className={classes.alert}>
						<div
							className={classes.severityIdentificator}
							style={{ backgroundColor: defineSeverityColor() }}
						/>
						<div className={classes.content}>
							<p>{text}</p>
							<div
								className={classes.iconButton}
								onClick={(e) => {
									e.stopPropagation();
									onClose();
								}}
							>
								<MdOutlineCancel className={classes.icon} />
							</div>
						</div>
					</div>
				)}
			</Transition>
		</div>
	);
}
