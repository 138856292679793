import { BrowserRouter, Route, Routes } from "react-router-dom";
import NotFound from "../components/NotFound";
import paths from "../paths";
import Account from "./Account/Account";
import LogIn from "./LogIn/LogIn";
import Profil from "./Profil/Profil";
import AccessoryEdition from "./AccessoryEdition/AccessoryEdition";
import ProfilEdition from "./ProfilEdition/ProfilEdition";

const Router = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route exact path={paths.account.baseUrl} element={<Account />} />
				<Route exact path={paths.login.baseUrl} element={<LogIn />} />
				<Route path={paths.profil.baseUrl} element={<Profil />} />
				<Route
					path={paths.accessoryEdition.baseUrl}
					element={<AccessoryEdition />}
				/>
				<Route path={paths.profilEdition.baseUrl} element={<ProfilEdition />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
		</BrowserRouter>
	);
};

export default Router;
