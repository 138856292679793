import { createStyles, Paper, Select } from "@mantine/core";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "../../components/Buttons/Button";
import BackButton from "../../components/Buttons/BackButton";
import CreatedByTapizi from "../../components/CreatedByTapizi";
import TextInput from "../../components/TextInputs";
import Text from "../../components/Text";

import { useUser } from "../../contexts/UserContextProvider";
import { useProfiles } from "../../contexts/ProfilesContextProvider";
import { useAccessories } from "../../contexts/AccessoriesContextProvider";
import { useToasts } from "../../contexts/ToastsContextProvider";
import paths from "../../paths";

const useStyles = createStyles((theme) => ({
	page: {
		display: "flex",
		flexDirection: "column",
		background: theme.colors.globalBackground,
		alignItems: "center",
		height: "100vh",
		paddingLeft: theme.spacing.xl,
		paddingRight: theme.spacing.xl,
		overflow: "hidden",
	},
	header: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
	},
	title: {
		marginTop: theme.spacing.xxl,
	},
	paper: {
		display: "flex",
		flexDirection: "column",
		height: "40%",
		width: "92%",
		justifyContent: "space-between",
		alignItems: "center",
		backgroundColor:
			theme.colorScheme === "dark"
				? theme.colors.darkBackground
				: theme.colors.lightGrey,
		position: "absolute",
		top: "30%",
	},
	input: {
		width: "100%",
	},
	select: {
		width: "100%",
	},
}));

const unselectedProfile = {
	value: null,
	label: "Aucun profil",
};

const AccessoryEdition = () => {
	const {
		state: { accessory, accessoryIdToCreate },
	} = useLocation();
	const { addToast } = useToasts();
	const { classes } = useStyles();
	const navigate = useNavigate();
	const { summaryProfiles } = useProfiles();
	const { createAccessory, updateAccessory } = useAccessories();
	const { user } = useUser();

	const [name, setName] = React.useState(accessory?.name ?? "");
	const [profile, setProfile] = React.useState(accessory?.profile);
	const [profilesList, setProfilesList] = React.useState([unselectedProfile]);

	const doAccessoryAction = () => {
		if (accessory) {
			updateAccessory(accessory.id, { name, profileId: profile })
				.then((e) => {
					if (!e) return Promise.reject();
					addToast("Votre accessoire a bien été modifié", {
						appearance: "success",
					});
					navigate(paths.account.baseUrl);
				})
				.catch(() => {
					addToast("Une erreur s'est produite", {
						appearance: "error",
					});
				});
		} else {
			createAccessory({ id: accessoryIdToCreate, name, profileId: profile })
				.then(() => {
					addToast("Votre accessoire a bien été ajouté", {
						appearance: "success",
					});
					navigate(paths.account.baseUrl);
				})
				.catch(() => {
					addToast("Une erreur s'est produite", {
						appearance: "error",
					});
				});
		}
	};

	React.useEffect(() => {
		setProfilesList([
			...summaryProfiles.map((profile) => ({
				value: profile.id,
				label: `${profile.title}`,
			})),
			unselectedProfile,
		]);
	}, [summaryProfiles]);

	React.useEffect(() => {
		setProfile(
			accessory?.profileId ? accessory.profileId : unselectedProfile.value
		);
	}, [accessory]);

	return (
		<div className={classes.page}>
			<div className={classes.header}>
				<BackButton url={paths.account.baseUrl} />
			</div>
			<div className={classes.title}>
				<Text variant="h1">Bonjour {user?.name}</Text>
			</div>
			<Paper className={classes.paper} shadow="xs" p="md">
				<Text variant="subtitle">
					{accessory
						? "Modifier votre accessoire"
						: "Ajout d'un nouvel accessoire"}
				</Text>
				<TextInput
					className={classes.input}
					placeholder="Veuillez nommer votre accessoire"
					label="Nom"
					required
					value={name}
					onChange={({ currentTarget }) => setName(currentTarget.value)}
					handleClearForm={() => setName("")}
				/>
				<Select
					className={classes.select}
					label="Profil"
					placeholder="Sélectionner un profil"
					data={profilesList}
					value={profile}
					onChange={setProfile}
				/>
				<Button onClick={doAccessoryAction}>Enregistrer</Button>
			</Paper>
			<CreatedByTapizi textColor="white" color="white" />
		</div>
	);
};

export default AccessoryEdition;
