import { Button, createStyles } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import paths from "../../paths";
import { useApi } from "../../contexts/ApiContextProvider";

const useStyles = createStyles((theme) => ({
	button: {
		marginTop: theme.spacing.xs,
		marginBottom: theme.spacing.md,
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 400,
		fontSize: 16,
		color: theme.colors.pink,
	},
}));

const ConnexionButton = (withBackButton) => {
	const { classes } = useStyles();
	const navigate = useNavigate();
	const { isConnected, disconnectUser } = useApi();

	if (isConnected)
		return (
			<Button
				className={classes.button}
				variant="subtle"
				onClick={() => disconnectUser()}
			>
				Se déconnecter
			</Button>
		);
	else
		return (
			<Button
				className={classes.button}
				variant="subtle"
				onClick={() => {
					navigate(paths.login.baseUrl, { state: { withBackButton } });
				}}
			>
				Se connecter
			</Button>
		);
};

export default ConnexionButton;
