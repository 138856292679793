import { createStyles } from "@mantine/core";
import Accessory from "./Accessory";

import Text from "../../../../components/Text";
import { useAccessories } from "../../../../contexts/AccessoriesContextProvider";
import "../index.css";

const useStyles = createStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		alignItems: "center",
	},
	list: {
		// Total height of 2 items + the bottom margin under the first one
		maxHeight: 130,
		overflowY: "scroll",
		overflowX: "hidden",
		width: "100%",
		marginBottom: theme.spacing.sm,
	},
	title: {
		margin: theme.spacing.sm,
	},
}));

const AccessoriesList = () => {
	const { classes } = useStyles();
	const { accessories } = useAccessories();

	return (
		<div className={classes.container}>
			<div className={classes.title}>
				<Text variant="title">Gérer mes accessoires</Text>
			</div>
			<div className={classes.list}>
				{accessories &&
					accessories.map((el) => <Accessory key={el.id} accessory={el} />)}
			</div>
		</div>
	);
};

export default AccessoriesList;
