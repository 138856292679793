import { createStyles, UnstyledButton } from "@mantine/core";
import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

import Text from "../Text";

const useStyles = createStyles((theme) => ({
	backButton: {
		width: "fit-content",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		gap: 6,
		paddingTop: theme.spacing.sm,
		paddingBottom: theme.spacing.sm,
		color: theme.colors.white,
		fontWeight: 600,
		fontSize: 14,
		lineHeight: 18,
		textAlign: "center",
	},
}));

const BackButton = ({ url }) => {
	const { classes } = useStyles();
	const navigate = useNavigate();

	return (
		<UnstyledButton
			className={classes.backButton}
			onClick={() => (url ? navigate(url) : navigate(-1))}
		>
			<IoIosArrowBack />
			<Text color="#ffffff">Retour</Text>
		</UnstyledButton>
	);
};

export default BackButton;
