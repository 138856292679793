import { createStyles, Grid, Pagination } from "@mantine/core";
import React from "react";
import { useSwipeable } from "react-swipeable";
import InformationButton from "./InformationButton";

const useStyles = createStyles((theme) => ({
	container: {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		overflow: "hidden",
		flexDirection: "column",
		gap: theme.spacing.sm,
		marginBottom: theme.spacing.md,
	},
	gridContainer: {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: theme.spacing.sm,
		height: "100%",
		transition: "transform 0.3s",
	},
	item: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	paper: {
		display: "flex",
		flexWrap: "nowrap",
		flexDirection: "row",
		width: "100%",
		height: "100%",
		overflow: "hidden",
	},
	paperPage: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		minWidth: "100%",
		minHeight: "100%",
	},
}));

const ITEM_PER_PAGE = 6;

const getPaginedData = (data) =>
	data.reduce((acc, el, idx) => {
		const pageIdx = Math.floor(idx / ITEM_PER_PAGE);

		if (!acc[pageIdx]) acc[pageIdx] = [];

		acc[pageIdx].push(el);

		return acc;
	}, []);

const Informations = ({
	phoneNumbers = [],
	cellphoneNumbers = [],
	mails = [],
	websites = [],
	pdfs = [],
	addresses = [],
}) => {
	const { classes } = useStyles();

	const [pageDisplayed, setPageDisplayed] = React.useState(0);

	// Group all data with type to know icon to display
	const allData = [
		...phoneNumbers?.map((el) => ({ text: el, type: "phone" })),
		...cellphoneNumbers?.map((el) => ({ text: el, type: "mobile" })),
		...mails?.map((el) => ({ text: el, type: "mail" })),
		...websites?.map((el) => ({ text: el, type: "web" })),
		...pdfs?.map((el) => ({ link: el.link, text: el.text, type: "pdf" })),
		...addresses.map((el) => ({ text: el, type: "address" })),
	];

	const sliderDataFormatted = getPaginedData(allData);

	// Add mobile swipe mechanic
	const handlers = useSwipeable({
		onSwipedLeft: () =>
			setPageDisplayed((e) => (e < sliderDataFormatted.length - 1 ? e + 1 : e)),
		onSwipedRight: () => setPageDisplayed((e) => (e > 0 ? e - 1 : e)),
		swipeDuration: 500,
		preventScrollOnSwipe: true,
		trackMouse: true,
	});

	return (
		<div {...handlers} className={classes.container}>
			<div className={classes.paper}>
				{sliderDataFormatted.map((items, idx) => (
					<div className={classes.paperPage} key={idx}>
						<Grid
							style={{ transform: `translateX(-${pageDisplayed * 100}%)` }}
							className={classes.gridContainer}
						>
							{items.map(({ link, text, type }) => (
								<Grid.Col key={text} span={6} className={classes.item}>
									<InformationButton link={link} text={text} icon={type} />
								</Grid.Col>
							))}
						</Grid>
					</div>
				))}
			</div>
			{sliderDataFormatted.length > 1 && (
				<Pagination
					total={sliderDataFormatted.length}
					page={pageDisplayed + 1}
					size="xs"
					radius="xl"
					color="gray"
					withControls={false}
					onChange={(newPage) => setPageDisplayed(newPage - 1)}
					styles={() => ({
						item: {
							backgroundColor: "#D5D5D5",
							color: "#D5D5D5",
							height: "12px !important",
							fontSize: "0px !important",
						},
					})}
				/>
			)}
		</div>
	);
};

export default Informations;
