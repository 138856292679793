import PropTypes from "prop-types";
import * as React from "react";
import { useApi } from "./ApiContextProvider";
import { useToasts } from "./ToastsContextProvider";

const UserContext = React.createContext();

/**
 * This context provider allows to manage user data
 */
const UserContextProvider = ({ children }) => {
	const { api, isConnected } = useApi();
	const { addToast } = useToasts();

	const [user, setUser] = React.useState();

	const getUser = React.useCallback(
		async () => await api(`/users/id`, "get"),
		[api]
	);

	const createUser = React.useCallback(
		async () => await api(`/users`, "post"),
		[api]
	);

	React.useEffect(() => {
		// On user connection, get his data
		// If user data don't exist, create it

		if (isConnected) {
			getUser()
				.then((res) => {
					if (res.status === 200) {
						setUser({ ...res.data, name: res?.data?.email.split("@")[0] });
					} else {
						createUser().then((res) => {
							if (res.status === 200) {
								setUser(res.data);
							}
						});
					}
				})
				.catch((err) => {
					createUser().then((res) => {
						if (res.status === 200) {
							setUser(res.data);
						}
					});
				});
		}
	}, [isConnected, getUser, createUser]);

	const updateUser = React.useCallback(
		async (data) => {
			try {
				await api(`/users`, "put", {
					data,
				});
				setUser((oldUser) => ({ ...oldUser, ...data }));
			} catch (e) {
				addToast("Vos modifications n'ont pas pu être sauvegardées", {
					appearance: "error",
				});
			}
		},
		[api, addToast]
	);

	const value = { createUser, getUser, updateUser, user };

	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserContextProvider.propTypes = {
	children: PropTypes.object.isRequired,
};

export const useUser = () => React.useContext(UserContext);

export default UserContextProvider;
