import * as React from "react";
import {
	createStyles,
	Text,
	TextInput as MantineTextInput,
	PasswordInput,
} from "@mantine/core";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { MdOutlineCancel } from "react-icons/md";
import { useFocusWithin } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		width: "100%",
		marginBottom: theme.spacing.md,
	},
	input: {
		width: "100%",
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 400,
		fontSize: 16,
	},
	clearIcon: {
		height: 18,
		width: 18,
		color:
			theme.colorScheme === "dark"
				? theme.colors.mantineTextGrey
				: theme.colors.black,
		transition: "all 150ms ease-in-out",
	},
	textError: {
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 400,
		fontSize: 12,
		color: theme.colors.pinterest,
		margin: 0,
		textAlign: "left",
	},
}));

const TextInput = ({
	value,
	error,
	disabled,
	onChange = () => {},
	onFocus = () => {},
	handleClearForm = () => {},
	placeholder,
	label,
	isPassword,
	required,
	backgroundColor,
}) => {
	const { classes } = useStyles();
	const { ref, focused } = useFocusWithin();

	const [touched, setTouched] = React.useState(false);

	const showError = value !== "" && touched;

	const _onFocus = () => {
		setTouched(true);
		return onFocus();
	};

	if (isPassword)
		return (
			<div className={classes.container}>
				<PasswordInput
					className={classes.input}
					placeholder={placeholder}
					label={label}
					visibilityToggleIcon={({ reveal, size }) =>
						reveal ? <FiEye size={size} /> : <FiEyeOff size={size} />
					}
					value={value}
					required={required}
					onChange={onChange}
					onFocus={_onFocus}
					sx={(theme) => ({
						backgroundColor: backgroundColor
							? backgroundColor
							: theme.colorScheme === "dark"
							? theme.colors.darkBackground
							: theme.colors.lightGrey,
					})}
				/>
				{showError && <Text className={classes.textError}>{error}</Text>}
			</div>
		);
	return (
		<div className={classes.container}>
			<MantineTextInput
				ref={ref}
				mode="outlined"
				label={label}
				className={classes.input}
				value={value}
				disabled={disabled}
				onChange={onChange}
				onFocus={_onFocus}
				placeholder={placeholder}
				required={required}
				rightSection={
					<MdOutlineCancel
						onClick={() => handleClearForm()}
						className={classes.clearIcon}
						style={{ opacity: value !== "" && focused ? 1 : 0 }}
					/>
				}
			/>

			{showError && <Text className={classes.textError}>{error}</Text>}
		</div>
	);
};

export default TextInput;
