import { createStyles, Title, Paper } from "@mantine/core";

import BackButton from "./Buttons/BackButton";
import SocialButton from "./SocialShareButtons/SocialButton";
import Text from "./Text";

const useStyles = createStyles((theme) => ({
	page: {
		display: "flex",
		flexDirection: "column",
		background: theme.colors.globalBackground,
		alignItems: "center",
		height: "100vh",
		paddingLeft: theme.spacing.xl,
		paddingRight: theme.spacing.xl,
	},
	header: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
	},
	paper: {
		display: "flex",
		flexDirection: "column",
		height: 400,
		width: "100%",
		justifyContent: "space-between",
		alignItems: "center",
		marginTop: "auto",
		marginBottom: "auto",
		padding: 24,
		backgroundColor:
			theme.colorScheme === "dark"
				? theme.colors.darkBackground
				: theme.colors.lightGrey,
		textAlign: "center",
	},

	possibilities: {
		width: "100%",
	},
	homePage: {
		textDecoration: "none",
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: 16,
		color:
			theme.colorScheme === "dark" ? theme.colors.white : theme.colors.dark,
	},
	socialNetworks: {
		display: "flex",
		justifyContent: "space-around",
		alignItems: "center",
		marginLeft: "auto",
		marginRight: "auto",
		width: "50%",
		marginTop: 16,
	},
}));

const NotFound = () => {
	const { classes } = useStyles();

	return (
		<div className={classes.page}>
			<div className={classes.header}>
				<BackButton />
			</div>

			<Paper className={classes.paper}>
				<Title order={4}>404 - Cette page n'existe pas</Title>
				<Text>
					Nous avons cherché partout, mais nous n'avons pas trouvé ce que vous
					cherchez. Trouvons un meilleur endroit pour vous :
				</Text>
				<div className={classes.possibilities}>
					<a
						href="https://tapizi.com/"
						target="_blank"
						rel="noreferrer"
						className={classes.homePage}
					>
						Cliquez ici pour revenir à la page principale
					</a>
					<Text>Vous pouvez nous suivre sur les réseaux sociaux :</Text>
					<div className={classes.socialNetworks}>
						<SocialButton
							url="https://www.instagram.com/tapizi_france/"
							socialNetwork="instagram"
						/>
						<SocialButton
							url="https://m.facebook.com/tapizifrance"
							socialNetwork="facebook"
						/>
					</div>
				</div>
			</Paper>
		</div>
	);
};

export default NotFound;
