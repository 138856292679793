import { createStyles } from "@mantine/core";
import React from "react";
import { IoIosClose } from "react-icons/io";

import AddButton from "../../../components/Buttons/AddButton";

import TextArea from "../../../components/TextArea";
import TextInput from "../../../components/TextInputs";

const useStyles = createStyles((theme) => ({
	paper: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
	},
	icon: {
		height: theme.spacing.lg,
		width: theme.spacing.lg,
	},
	header: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: theme.spacing.sm,
	},
	input: {
		width: "100%",
		marginBottom: theme.spacing.sm,
	},
	address: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	closeIcon: {
		height: theme.spacing.xl,
		width: theme.spacing.xl,
		marginRight: theme.spacing.sm,
	},
	plusIcon: {
		marginRight: theme.spacing.xs,
	},
}));

const ContactForm = ({
	handleChange,
	title,
	description,
	addresses,
}) => {
	const { classes } = useStyles();

	const addAddress = () => handleChange("addresses", [...addresses, ""]);

	const updateAddress = (newAddress, idxToUpdate) =>
		handleChange(
			"addresses",
			addresses.map((address, idx) =>
				idxToUpdate === idx ? newAddress : address
			)
		);

	const removeAddress = (idx) =>
		handleChange(
			"addresses",
			addresses.filter((_, i) => idx !== i)
		);

	return (
		<div className={classes.form}>
			<TextInput
				placeholder="Veuillez renseignez le titre du profil"
				label="Titre"
				required
				value={title}
				onChange={({ currentTarget }) =>
					handleChange("title", currentTarget.value)
				}
				handleClearForm={() => handleChange("title", "")}
			/>
			<TextArea
				placeholder="Veuillez renseignez une description"
				label="Description"
				required
				value={description}
				onChange={({ currentTarget }) =>
					handleChange("description", currentTarget.value)
				}
				handleClearForm={() => handleChange("description", "")}
			/>
			{addresses.map((address, idx) => (
				<div className={classes.address} key={`address_${idx}`}>
					<IoIosClose
						className={classes.closeIcon}
						onClick={() => {
							removeAddress(idx);
						}}
					/>
					<TextInput
						placeholder="ex: 15 rue du château 06160 Antibes"
						label="Adresse"
						value={address}
						onChange={({ currentTarget }) =>
							updateAddress(currentTarget.value, idx)
						}
						handleClearForm={() => updateAddress("", idx)}
					/>
				</div>
			))}
			<AddButton label="Nouvelle addresse" onClick={addAddress} />
		</div>
	);
};

export default ContactForm;
