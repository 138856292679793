import React from "react";

import { FileButton, createStyles } from "@mantine/core";
import Avatar from "../../images/avatar.png";

export const AVATAR_HEIGHT_SMALL = 50;
export const AVATAR_HEIGHT = 120;

const useStyles = createStyles(() => ({
	avatar: {
		position: "relative",
		top: -AVATAR_HEIGHT / 2,
		borderRadius: "50%",
		objectFit: "cover",
	},
	smallAvatar: {
		borderRadius: "50%",
		objectFit: "cover",
	},
}));

const UserPicture = ({ variant, handleChange, value }) => {
	const { classes } = useStyles();

	if (variant === "header" && !handleChange)
		return (
			<img
				className={classes.avatar}
				src={value ?? Avatar}
				alt="avatar"
				width={AVATAR_HEIGHT}
				height={AVATAR_HEIGHT}
				style={{
					cursor: "pointer",
				}}
			/>
		);
	else if (variant === "header" && handleChange)
		return (
			<FileButton onChange={handleChange} accept="image/png,image/jpeg">
				{(props) => (
					<img
						{...props}
						className={classes.avatar}
						src={value ?? Avatar}
						alt="avatar"
						width={AVATAR_HEIGHT}
						height={AVATAR_HEIGHT}
						style={{
							cursor: "pointer",
						}}
					/>
				)}
			</FileButton>
		);
	else
		return (
			<img
				className={classes.smallAvatar}
				src={value ?? Avatar}
				alt="avatar"
				width={AVATAR_HEIGHT_SMALL}
				height={AVATAR_HEIGHT_SMALL}
				style={{
					cursor: "pointer",
				}}
			/>
		);
};

export default UserPicture;
