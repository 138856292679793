import { createStyles, Divider } from "@mantine/core";
import React from "react";
import { IoIosClose } from "react-icons/io";

import AddButton from "../../../components/Buttons/AddButton";
import TextInput from "../../../components/TextInputs";

const useStyles = createStyles((theme) => ({
	inputContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	closeIcon: {
		height: theme.spacing.xl,
		width: theme.spacing.xl,
		marginRight: theme.spacing.sm,
	},
	divider: {
		opacity: 0.5,
	},
}));

const Informations = ({
	handleChange,
	phoneNumbers,
	cellphoneNumbers,
	emails,
}) => {
	const { classes } = useStyles();

	const addData = (field, oldData) => handleChange(field, [...oldData, ""]);

	const updateData = (field, oldData, idxToUpdate, newAddress) =>
		handleChange(
			field,
			oldData.map((address, idx) =>
				idxToUpdate === idx ? newAddress : address
			)
		);

	const removeData = (field, oldData, idx) =>
		handleChange(
			field,
			oldData.filter((_, i) => idx !== i)
		);

	return (
		<div className={classes.form}>
			{emails.map((email, index) => (
				<div className={classes.inputContainer} key={`email_${index}`}>
					<IoIosClose
						className={classes.closeIcon}
						onClick={() => {
							removeData("emails", emails, index);
						}}
					/>
					<TextInput
						placeholder="ex: exemple@google.com"
						label="Email"
						value={email}
						onChange={({ currentTarget }) =>
							updateData("emails", emails, index, currentTarget.value)
						}
						handleClearForm={() => updateData("emails", emails, index, "")}
					/>
				</div>
			))}
			<AddButton
				label="Nouvel email"
				onClick={() => addData("emails", emails)}
			/>
			<Divider my="sm" color="dark" className={classes.divider} />
			{phoneNumbers.map((phone, index) => (
				<div className={classes.inputContainer} key={`phone_${index}`}>
					<IoIosClose
						className={classes.closeIcon}
						onClick={() => {
							removeData("phoneNumbers", phoneNumbers, index);
						}}
					/>
					<TextInput
						placeholder="ex: 06.66.66.66.66"
						label="Téléphone"
						value={phone}
						onChange={({ currentTarget }) =>
							updateData(
								"phoneNumbers",
								phoneNumbers,
								index,
								currentTarget.value
							)
						}
						handleClearForm={() =>
							updateData("phoneNumbers", phoneNumbers, index, "")
						}
					/>
				</div>
			))}
			<AddButton
				label="Nouveau téléphone portable"
				onClick={() => addData("phoneNumbers", phoneNumbers)}
			/>
			<Divider my="sm" color="dark" className={classes.divider} />
			{cellphoneNumbers.map((cellPhone, index) => (
				<div className={classes.inputContainer} key={`cellphone_${index}`}>
					<IoIosClose
						className={classes.closeIcon}
						onClick={() => {
							removeData("cellphoneNumbers", cellphoneNumbers, index);
						}}
					/>
					<TextInput
						placeholder="ex: 09.99.99.99.99"
						label="Téléphone fixe"
						value={cellPhone}
						onChange={({ currentTarget }) =>
							updateData(
								"cellphoneNumbers",
								cellphoneNumbers,
								index,
								currentTarget.value
							)
						}
						handleClearForm={() =>
							updateData("cellphoneNumbers", cellphoneNumbers, index, "")
						}
					/>
				</div>
			))}
			<AddButton
				label="Nouveau téléphone fixe"
				onClick={() => addData("cellphoneNumbers", cellphoneNumbers)}
			/>
		</div>
	);
};

export default Informations;
