import { createStyles, Paper } from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router-dom";

import paths from "../../../../../paths";

import Button from "../../../../../components/Buttons/Button";
import Text from "../../../../../components/Text";
import UserPicture, {
	AVATAR_HEIGHT_SMALL,
} from "../../../../../components/UserPicture/UserPicture";

import { useProfiles } from "../../../../../contexts/ProfilesContextProvider";

const BUCKET_NAME = "tapizi-v1";

const useStyles = createStyles((theme) => ({
	paper: {
		display: "flex",
		flexDirection: "column",
		height: 110,
		width: "100%",
		alignItems: "center",
		marginBottom: theme.spacing.md,
	},
	topContainer: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		gap: theme.spacing.md,
	},
	userPictureContainer: {
		display: "flex",
	},
	textsContainer: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		maxWidth: `calc(100% - ${AVATAR_HEIGHT_SMALL * 1.5}px)`,
	},
	title: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	icon: {
		height: theme.spacing.md + 2,
		width: theme.spacing.md + 2,
		color:
			theme.colorScheme === "dark" ? theme.colors.white : theme.colors.blue,
	},
}));

const ProfileItem = ({ id, title, description }) => {
	const { classes } = useStyles();
	const navigate = useNavigate();

	const { getProfilePictureOfProfile } = useProfiles();

	const [profilePicture, setProfilePicture] = React.useState();

	const getInitialProfilePicture = React.useCallback(async () => {
		const initialProfilePicture = await getProfilePictureOfProfile(id);
		if (initialProfilePicture)
			setProfilePicture({
				initialProfilePicture,
				url: `https://storage.googleapis.com/${BUCKET_NAME}/${initialProfilePicture.name}`,
			});
	}, [getProfilePictureOfProfile, id]);

	React.useEffect(() => {
		if (id) {
			getInitialProfilePicture();
		}
	}, [id, getInitialProfilePicture]);

	return (
		<Paper
			className={classes.paper}
			shadow="xs"
			p="md"
			onClick={() =>
				navigate(paths.profilEdition.baseUrl, { state: { profileId: id } })
			}
		>
			<div className={classes.topContainer}>
				<div className={classes.userPictureContainer}>
					<UserPicture value={profilePicture?.url} />
				</div>
				<div className={classes.textsContainer}>
					<div className={classes.title}>
						<Text variant="subtitle" className={classes.text}>
							{title}
						</Text>
					</div>
					<Text variant="overflowText">{description}</Text>
				</div>
			</div>

			<Button transparent>Modifier ce profil</Button>
		</Paper>
	);
};

export default ProfileItem;
