import { createStyles, Text, Textarea as MantineTextArea } from "@mantine/core";
import React from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useFocusWithin } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		width: "100%",
	},
	input: {
		width: "100%",
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 400,
		fontSize: 16,
		backgroundColor:
			theme.colorScheme === "dark"
				? theme.colors.darkBackground
				: theme.colors.white,
	},
	clearIcon: {
		height: 18,
		width: 18,
		color:
			theme.colorScheme === "dark"
				? theme.colors.mantineTextGrey
				: theme.colors.black,
	},
	textError: {
		marginBottom: theme.spacing.md,
		color: theme.colors.pinterest,
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 400,
		fontSize: 12,
		margin: 0,
		textAlign: "left",
	},
}));

const TextArea = ({
	value,
	error,
	disabled,
	onChange = () => {},
	onFocus = () => {},
	handleClearForm = () => {},
	placeholder,
	label,
	required,
}) => {
	const { classes } = useStyles();
	const { ref, focused } = useFocusWithin();

	const [touched, setTouched] = React.useState(false);

	const showError = value !== "" && touched;

	const _onFocus = () => {
		setTouched(true);
		return onFocus();
	};

	return (
		<div className={classes.container}>
			<MantineTextArea
				ref={ref}
				className={classes.input}
				value={value}
				label={label}
				disabled={disabled}
				onChange={onChange}
				onFocus={_onFocus}
				placeholder={placeholder}
				required={required}
				rightSection={
					<MdOutlineCancel
						onClick={() => handleClearForm()}
						className={classes.clearIcon}
						style={{ opacity: value !== "" && focused ? 1 : 0 }}
					/>
				}
			/>

			{showError && <Text className={classes.textError}>{error}</Text>}
		</div>
	);
};

export default TextArea;
