import PropTypes from "prop-types";
import * as React from "react";
import AccessoriesContextProvider from "./AccessoriesContextProvider";
import ApiContextProvider from "./ApiContextProvider";
import ProfilesContextProvider from "./ProfilesContextProvider";
import ThemeContextProvider from "./ThemeContextProvider";
import UserContextProvider from "./UserContextProvider";
import AddToastProvider from "./ToastsContextProvider";

const RootContextProvider = ({ children }) => {
	return (
		<ThemeContextProvider>
			<AddToastProvider>
				<ApiContextProvider>
					<UserContextProvider>
						<ProfilesContextProvider>
							<AccessoriesContextProvider>
								{children}
							</AccessoriesContextProvider>
						</ProfilesContextProvider>
					</UserContextProvider>
				</ApiContextProvider>
			</AddToastProvider>
		</ThemeContextProvider>
	);
};

RootContextProvider.propTypes = {
	children: PropTypes.object.isRequired,
};

export default RootContextProvider;
