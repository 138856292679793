import React from "react";
import "./index.css";

import ReactDOM from "react-dom/client";
import Router from "./pages/Router";
import RootContextProvider from "./contexts/RootContextProvider";

const AppTest = () => {
	return (
		<React.StrictMode>
			<RootContextProvider>
				<Router />
			</RootContextProvider>
		</React.StrictMode>
	);
};

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);
root.render(<AppTest />);
