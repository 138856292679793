import { createStyles, Loader, Paper } from "@mantine/core";
import React from "react";

import Text from "./Text";

const useStyles = createStyles((theme) => ({
	loaderContainer: {
		height: "100vh",
		width: "100vw",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		background: theme.colors.globalBackground,
		paddingLeft: theme.spacing.xl,
		paddingRight: theme.spacing.xl,
	},
	paper: {
		height: 400,
		width: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		gap: 24,
		marginTop: "auto",
		marginBottom: "auto",
		backgroundColor:
			theme.colorScheme === "dark"
				? theme.colors.darkBackground
				: theme.colors.lightGrey,
	},
}));

const Loading = () => {
	const { classes } = useStyles();

	return (
		<div className={classes.loaderContainer}>
			<Paper className={classes.paper}>
				<Loader variant="dots" color="indigo" />
				<Text>Chargement de la page en cours</Text>
			</Paper>
		</div>
	);
};

export default Loading;
