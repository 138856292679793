import { createStyles } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/Buttons/Button";
import paths from "../../../../paths";
import ProfileItem from "./components/ProfileItem";
import { useProfiles } from "../../../../contexts/ProfilesContextProvider";

import Text from "../../../../components/Text";
import "../index.css";

const useStyles = createStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		alignItems: "center",
		// Total height of 2 items + the bottom margin under the first one
		maxHeight: 340,
		marginBottom: theme.spacing.lg,
	},
	list: {
		overflowY: "scroll",
		overflowX: "hidden",
		width: "100%",
		marginBottom: theme.spacing.sm,
	},
	title: {
		margin: theme.spacing.sm,
	},
}));

const ProfilesList = () => {
	const { classes } = useStyles();
	const { summaryProfiles } = useProfiles();
	const navigate = useNavigate();

	return (
		<div className={classes.container}>
			<div className={classes.title}>
				<Text variant="title">Gérer mes profils</Text>
			</div>
			<div className={classes.list}>
				{summaryProfiles.map((profile) => (
					<ProfileItem
						key={profile.id}
						id={profile.id}
						title={profile.title}
						description={profile.description}
					/>
				))}
			</div>
			<Button onClick={() => navigate(paths.profilEdition.baseUrl)}>
				Créer un profil
			</Button>
		</div>
	);
};

export default ProfilesList;
