import { createStyles, Paper } from "@mantine/core";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import paths from "../../paths";
import { useApi } from "../../contexts/ApiContextProvider";
import { useAccessories } from "../../contexts/AccessoriesContextProvider";
import { useToasts } from "../../contexts/ToastsContextProvider";
import * as yup from "yup";

import Button from "../../components/Buttons/Button";
import BackButton from "../../components/Buttons/BackButton";
import TextInput from "../../components/TextInputs";
import Text from "../../components/Text";

const useStyles = createStyles((theme) => ({
	page: {
		display: "flex",
		flexDirection: "column",
		background: theme.colors.globalBackground,
		alignItems: "center",
		height: "100vh",
		paddingLeft: theme.spacing.xl,
		paddingRight: theme.spacing.xl,
	},
	header: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
	},
	paper: {
		display: "flex",
		flexDirection: "column",
		height: 400,
		width: "100%",
		justifyContent: "space-between",
		alignItems: "center",
		marginTop: "auto",
		marginBottom: "auto",
		backgroundColor:
			theme.colorScheme === "dark"
				? theme.colors.darkBackground
				: theme.colors.lightGrey,
	},
	input: {
		width: "100%",
	},
	connexionBtn: {
		backgroundColor: theme.colors.blue,
		borderRadius: theme.spacing.lg,
	},
	navigationBtn: {
		backgroundColor: "transparent",
		color:
			theme.colorScheme === "dark" ? theme.colors.white : theme.colors.blue,
	},
}));

const LogIn = () => {
	const { classes } = useStyles();
	const navigate = useNavigate();
	const { state } = useLocation();
	const { connectUser, isConnected, resetUserPassword } = useApi();
	const { getAccessoriesKeyById } = useAccessories();
	const { addToast } = useToasts();

	const [form, setForm] = React.useState({ email: "", password: "" });
	const [isValidFields, setIsValidFields] = React.useState(false);

	const [isForgotPassword, setIsForgotPassword] = React.useState(false);

	const handleIsForgotPassword = (value) => setIsForgotPassword(value);

	const connect = () =>
		connectUser(form.email, form.password)
			.then(() => {
				addToast("Vous êtes connecté", {
					appearance: "success",
				});
			})
			.catch(() =>
				addToast("Une erreur s'est produite", {
					appearance: "error",
				})
			);

	const schema = yup.object().shape({
		email: yup.string().email("L'email doit être valide."),
		password: yup
			.string()
			.required("Le mot de passe est obligatoire.")
			.min(8, "Le mot de passe doit faire minimum 8 caractères."),
	});

	const [errors, setErrors] = React.useState({
		email: "L'email doit être valide.",
		password: "Le mot de passe doit faire minimum 8 caractères.",
	});

	const updateForm = (key, value) =>
		setForm((oldForm) => {
			const newForm = { ...oldForm, [key]: value };
			updateErrors({ key, form: newForm });
			return newForm;
		});

	const updateErrors = ({ key, form }) =>
		schema
			.validateAt(key, form)
			.then(() =>
				setErrors((oldErrors) => ({ ...oldErrors, [key]: undefined }))
			)
			.catch((error) =>
				setErrors((oldErrors) => ({ ...oldErrors, [key]: error.message }))
			);

	React.useEffect(() => {
		if (isConnected && state?.accessoryIdToCreate) {
			getAccessoriesKeyById(state?.accessoryIdToCreate)
				.then((isAvailable) => {
					if (isAvailable) {
						state?.accessoryIdToCreate
							? navigate(paths.accessoryEdition.baseUrl, {
									state: { accessoryIdToCreate: state.accessoryIdToCreate },
							  })
							: navigate(paths.account.baseUrl);
					} else {
						addToast("Une erreur s'est produite", {
							appearance: "error",
						});
						navigate(paths.profil.baseUrl);
					}
				})
				.catch(() => {
					addToast("Une erreur s'est produite", {
						appearance: "error",
					});
					navigate(paths.profil.baseUrl);
				});
		}
	}, [getAccessoriesKeyById, addToast, state, isConnected, navigate]);

	React.useEffect(() => {
		setIsValidFields(
			Object.values(errors).every((error) => error === undefined)
		);
	}, [errors]);

	return (
		<div className={classes.page}>
			{state?.withBackButton && (
				<div className={classes.header}>
					<BackButton />
				</div>
			)}
			{!isForgotPassword ? (
				<Paper className={classes.paper} shadow="xs" p="md">
					<Text variant="title">Se connecter</Text>
					<TextInput
						placeholder="Veuillez renseignez votre mail"
						label="Adresse mail"
						required
						value={form.email}
						onChange={({ currentTarget }) =>
							updateForm("email", currentTarget.value)
						}
						handleClearForm={() => updateForm("email", "")}
						error={errors.email}
					/>
					<TextInput
						className={classes.input}
						placeholder="Veuillez renseignez votre mot de passe"
						label="Mot de passe"
						required
						value={form.password}
						onChange={({ currentTarget }) =>
							updateForm("password", currentTarget.value)
						}
						error={errors.password}
						handleClearForm={() => updateForm("password", "")}
						isPassword
					/>
					<Button onClick={connect} disabled={!isValidFields}>
						Connexion
					</Button>
					<Button transparent onClick={() => handleIsForgotPassword(true)}>
						Mot de passe oublié ?
					</Button>
				</Paper>
			) : (
				<Paper className={classes.paper} shadow="xs" p="md">
					<Text variant="title">Mot de passe oublié</Text>
					<Text>
						Renseignez votre mail ici et vous recevrez un lien de
						réinitialisation de votre mot de passe
					</Text>
					<TextInput
						placeholder="Veuillez renseignez votre mail"
						label="Adresse mail"
						required
						value={form.email}
						onChange={({ currentTarget }) =>
							updateForm("email", currentTarget.value)
						}
						handleClearForm={() => updateForm("email", "")}
						error={errors.email}
					/>
					<Button
						onClick={() => {
							resetUserPassword(form.email).then(() => {
								addToast(
									"Un mail de réinitialisation vient de vous être envoyé",
									{
										appearance: "success",
									}
								);
								updateForm("email", "");
							});
						}}
					>
						J'ai oublié mon mot de passe
					</Button>
					<Button transparent onClick={() => handleIsForgotPassword(false)}>
						Revenir a l'écran de connexion
					</Button>
				</Paper>
			)}
		</div>
	);
};

export default LogIn;
