import { Global } from "@mantine/core";

const GlobalTheme = () => {
	return (
		<Global
			styles={(theme) => ({
				"*, *::before, *::after": {
					boxSizing: "border-box",
				},

				body: {
					...theme.fn.fontStyles(),
					backgroundColor:
						theme.colorScheme === "dark"
							? theme.colors.darkBackground
							: theme.colors.lightBackground,
					color:
						theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
					lineHeight: theme.lineHeight,
					margin: 0,
					button: {
						color:
							theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
					},
				},
			})}
		/>
	);
};

export default GlobalTheme;
