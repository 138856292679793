import { ColorSchemeProvider, MantineProvider } from "@mantine/core";
import { useColorScheme } from "@mantine/hooks";
import PropTypes from "prop-types";
import * as React from "react";
import GlobalTheme from "../theme/GlobalTheme";
import mantineTheme from "../theme/theme";

const ThemeContext = React.createContext();

/**
 * This context provider allows to manage the app theme
 */
const ThemeContextProvider = ({ children }) => {
	// hook will return either 'dark' or 'light' on client
	// and always 'light' during ssr as window.matchMedia is not available
	const preferredColorScheme = useColorScheme("light");
	const [colorScheme, setColorScheme] = React.useState(preferredColorScheme);

	const toggleColorScheme = () =>
		setColorScheme((value) => (value === "dark" ? "light" : "dark"));

	const value = { colorScheme, toggleColorScheme };

	return (
		<ThemeContext.Provider value={value}>
			<ColorSchemeProvider
				colorScheme={colorScheme}
				toggleColorScheme={toggleColorScheme}
			>
				<MantineProvider theme={{ ...mantineTheme, colorScheme }}>
					<GlobalTheme />
					{children}
				</MantineProvider>
			</ColorSchemeProvider>
		</ThemeContext.Provider>
	);
};

ThemeContextProvider.propTypes = {
	children: PropTypes.object.isRequired,
};

export const useTheme = () => React.useContext(ThemeContext);

export default ThemeContextProvider;
