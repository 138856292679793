import { Input, createStyles } from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router-dom";

import CreatedByTapizi from "../../components/CreatedByTapizi";
import AccessoriesList from "./components/AccessoriesList/AccessoriesList";
import ProfilesList from "./components/ProfilesList/ProfilesList";

import { useApi } from "../../contexts/ApiContextProvider";
import { useTheme } from "../../contexts/ThemeContextProvider";
import { useToasts } from "../../contexts/ToastsContextProvider";
import { useUser } from "../../contexts/UserContextProvider";

import ConnexionButton from "../../components/Buttons/ConnexionButton";
import Text from "../../components/Text";
import paths from "../../paths";

const useStyles = createStyles((theme) => ({
	page: {
		display: "flex",
		flexDirection: "column",
		background: theme.colors.globalBackground,
		height: "100vh",
		overflow: "scroll",
	},
	backButton: {
		padding: "0 12px",
	},
	credentials: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		marginBottom: theme.spacing.xxl * 1.2,
		marginTop: theme.spacing.xxl * 1.2,
	},
	helloContainer: {
		marginRight: theme.spacing.md,
	},
	input: {
		border: "2px solid white",
		borderRadius: "5px",
	},
	container: {
		display: "flex",
		flexDirection: "column",
		backgroundColor:
			theme.colorScheme === "dark"
				? theme.colors.darkBackground
				: theme.colors.lightGrey,
		alignItems: "center",
		borderTopLeftRadius: "100% 20%",
		borderTopRightRadius: "100% 20%",
		height: "100%",
		width: "100%",
		padding: theme.spacing.lg,
	},
}));

const Account = () => {
	const { classes } = useStyles();
	const { user, updateUser } = useUser();
	const { colorScheme } = useTheme();
	const navigate = useNavigate();
	const { isConnected } = useApi();
	const { addToast } = useToasts();

	const [isOpen, setIsOpen] = React.useState(false);
	const [username, setUsername] = React.useState("");

	const handleIsOpen = (e) => setIsOpen(e);

	const handleSave = () => {
		if (user?.name !== username && username !== "") {
			updateUser({ name: username });
		} else if (username === "") {
			addToast("Votre nom doit comporter au moins un caractère.", {
				appearance: "error",
			});
			return;
		}
		handleIsOpen(false);
	};

	React.useEffect(() => {
		if (!isConnected) navigate(paths.login.baseUrl);
	});

	React.useEffect(() => {
		if (user?.name) setUsername(user?.name);
	}, [user]);

	return (
		<div className={classes.page}>
			<div className={classes.credentials}>
				<div className={classes.helloContainer}>
					<Text variant="h1">Bonjour</Text>
				</div>
				{!isOpen ? (
					<div onClick={() => handleIsOpen(true)}>
						<Text variant="h1">{user?.name} !</Text>
					</div>
				) : (
					<Input
						autoFocus
						variant="unstyled"
						className={classes.input}
						onBlur={() => handleSave()}
						styles={() => ({
							input: {
								color: "white",
								fontSize: "bold",
							},
						})}
						value={username}
						onChange={({ target }) => {
							setUsername(target.value);
						}}
					></Input>
				)}
			</div>
			<div className={classes.container}>
				<ProfilesList />
				<AccessoriesList />
				<ConnexionButton />
				<CreatedByTapizi
					textColor={colorScheme === "dark" ? "white" : "black"}
					color={colorScheme === "dark" ? "white" : "blue"}
				/>
			</div>
		</div>
	);
};

export default Account;
