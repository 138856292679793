import { createStyles } from "@mantine/core";
import React from "react";
import SocialButton from "../../../../../components/SocialShareButtons/SocialButton";

import TextInput from "../../../../../components/TextInputs";

const useStyles = createStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		alignItems: "center",
		gap: theme.spacing.md,
		margin: "12px 0 12px 0",
	},
	textInput: { width: "85%", height: 36 },
}));

const SocialNetwork = ({ socialNetwork, updateSocialNetwork, url }) => {
	const { classes } = useStyles();

	if (socialNetwork)
		return (
			<div className={classes.container}>
				<SocialButton socialNetwork={socialNetwork} url={url} />
				<div className={classes.textInput}>
					<TextInput
						placeholder={`${socialNetwork}.com/example`}
						value={url}
						onChange={({ currentTarget }) =>
							updateSocialNetwork(socialNetwork, currentTarget.value)
						}
						handleClearForm={() => updateSocialNetwork(socialNetwork, "")}
					/>
				</div>
			</div>
		);
	else return <></>;
};

export default SocialNetwork;
